import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../img/categories/cat-1.jpg";
import useAxios from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import NoImage from "../../img/catergory_no_image.png";
import CryptoJS from "crypto-js";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
      },
    },
  ],
};

function CategorySlider() {
  const axios = useAxios();
  const slider = React.useRef(null);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  const secretKey = "12345";

  const getAllSubCategories = async () => {
    try {
      const response = await axios.get("/general/all_sub_categories");
      const result = response?.data;

      setSubCategories(result?.data || []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    getAllSubCategories();
  }, []);

  return (
    <section className="categories">
      <div className="container">
        <div className="row">
          {subCategories?.length ? (
            <div className="col-12" style={{ position: "relative" }}>
              <button
                onClick={() => slider?.current?.slickPrev()}
                class="previous_next round"
              >
                <i class="fa fa-angle-left"></i>
              </button>
              <Slider {...sliderSettings} ref={slider}>
                {subCategories?.map((subCategory) => (
                  <section key={subCategory.id}>
                    <div
                      onClick={() => {
                        const encryptedId = CryptoJS.AES.encrypt(
                          subCategory?.id.toString(),
                          secretKey
                        ).toString();
                        navigate(
                          `/shop-grid/${
                            subCategory?.url_key
                          }/${encodeURIComponent(encryptedId)}`
                        );
                      }}
                      className="categories__item set-bg no__cover"
                      style={{
                        backgroundImage: `url(${
                          subCategory.image_url || NoImage
                        })`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        cursor: "pointer",
                      }}
                    >
                      <h5>
                        <a>{subCategory.category_name}</a>
                      </h5>
                    </div>
                  </section>
                ))}
              </Slider>
              <button
                onClick={() => slider?.current?.slickNext()}
                class=" next_next round"
              >
                <i class="fa fa-angle-right"></i>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default CategorySlider;
