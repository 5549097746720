import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/product_no_image.png";
import Logout from "./Logout";
import Loader from "../../components/Loader";
import nodatafound from "../../img/nodatafound.jpg";
import { capitalizeWords, encryptId } from "../../utils/helpers";

function Orders() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);

  // Fetch orders
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/order/get_my_orders");

      if (response?.data?.success) {
        setOrders(response?.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Pagination Logic
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getVisiblePages = () => {
    const totalPages = Math.ceil(orders.length / itemsPerPage);
    const visiblePages = [];

    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      visiblePages.push(1, 2);

      if (currentPage > 4) visiblePages.push("...");

      for (
        let i = Math.max(3, currentPage - 1);
        i <= Math.min(totalPages - 2, currentPage + 1);
        i++
      ) {
        visiblePages.push(i);
      }

      if (currentPage < totalPages - 3) visiblePages.push("...");

      visiblePages.push(totalPages - 1, totalPages);
    }

    return visiblePages;
  };

  // Toggle row expansion for items visibility
  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const secretKey = "12345";

  // Handle click to navigate to order details
  const handleClick = (orderId) => {
    const encryptedId = encryptId(orderId, secretKey);
    navigate(`/profile/orders/orderdetails/${encodeURIComponent(encryptedId)}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  return (
    <div className="section content">
      {loading && <Loader />}
      <div className="container">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold heading">Your Orders</h4>
          </div>
        </div>

        <div className="row mb-5">
          {/* Sidebar */}
          <div className="col-lg-3">
            <ul className="list-group tabcss">
              <Link to="/profile">
                <li className="list-group-item">
                  <i
                    className="fa fa-user-circle-o"
                    style={{ marginRight: "5px" }}
                  ></i>
                  My Profile
                </li>
              </Link>

              <Link to="/profile/favourite">
                <li className="list-group-item">
                  <i className="fa fa-heart" style={{ marginRight: "5px" }}></i>
                  Wishlist
                </li>
              </Link>

              <Link to="/profile/orders">
                <li className="list-group-item active1">
                  <i
                    className="fa fa-shopping-bag"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Orders
                </li>
              </Link>

              <Logout />
            </ul>
          </div>

          {/* Orders List */}
          <div className="col-lg-9">
            {currentOrders.length > 0 ? (
              <div className="row mt-4">
                {currentOrders.map((order) => (
                  <div className="col-12 mb-2 mt-2" key={order.id}>
                    <div
                      className="card"
                      onClick={() => handleClick(order.id)} // Navigate to order details page
                      style={{ cursor: "pointer" }} // To indicate the card is clickable
                    >
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h5 className="card-title">{order.order_number}</h5>
                            <p className="card-text">
                              Date: {formatDate(order.created_date)}
                            </p>
                            <p className="card-text">Total: ₹ {order.amount}</p>
                            <span
  className={`badge ${
    order.is_cancelled === 1
      ? "bg-secondary"
      : order.payment_status === "captured"
      ? "bg-success"
      : order.payment_status === "failed"
      ? "bg-danger"
      : "bg-warning"
  }`}
  style={{ color: "#fff" }}
>
  {order.is_cancelled === 1
    ? "Cancelled"
    : order.payment_status === "captured"
    ? "Complete"
    : order.payment_status === "failed"
    ? "Failed"
    : "Pending"}
</span>

                          </div>
                         
                          <div>
                            <button
                              className="btn btn-info btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleRow(order.id);
                              }}
                            >
                              {expandedRows[order.id]
                                ? "Hide Items"
                                : "View Items"}
                            </button>
                          </div>
                        </div>
                        {expandedRows[order.id] && (
                          <div className="mt-3">
                            <ul>
                              {order.order_items.map((item) => (
                                <li
                                  key={item.id}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <div
                                    className="featured__item__pic"
                                    style={{
                                      backgroundImage: `url(${
                                        item.base_image_url || NoImage
                                      })`,
                                      width: "50px",
                                      height: "50px",
                                      backgroundSize: "contain",
                                      backgroundPosition: "center",
                                    }}
                                  ></div>
                                  <div style={{ marginLeft: "10px" }}>
                                    <span>
                                      {capitalizeWords(item.product_name || "")}{" "}
                                      - Qty: {item.quantity}
                                    </span>
                                    <div>
                                      Price: ₹{" "}
                                      {parseFloat(
                                        Number(item.mainRowAmount || 0) /
                                          Number(item.quantity || 1)
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-data">
                <img
                  src={nodatafound}
                  alt="No data found"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    margin: "20px auto",
                    display: "block",
                  }}
                />
                {/* <p style={{ textAlign: "center" }}>
                No products in the cart
              </p> */}
              </div>
            )}

            <div className="d-flex justify-content-center mt-4">
              <nav>
                <ul className="pagination">
                  {/* Previous Button */}
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                  </li>

                  {/* Dynamic Page Buttons */}
                  {getVisiblePages().map((page, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      } ${page === "..." ? "disabled" : ""}`}
                    >
                      {page === "..." ? (
                        <span className="page-link">...</span>
                      ) : (
                        <button
                          className="page-link"
                          onClick={() => paginate(page)}
                        >
                          {page}
                        </button>
                      )}
                    </li>
                  ))}

                  {/* Next Button */}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(orders.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(currentPage + 1)}
                      disabled={
                        currentPage === Math.ceil(orders.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
