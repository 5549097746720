import React, { useEffect } from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetMeta, setMeta } from "../../reduxStore/Meta";
import { decode } from "he";

const About = () => {
  const { setting } = useSelector((state) => state.Setting);
  const dispatch = useDispatch();

  useEffect(() => {
    // Set meta for Contact Page
    dispatch(
      setMeta({
        title: "Edutools.in - About Us",
        description:
          "Get in touch with us at Edutools.in. We are here to assist you with your queries and provide the best solutions for your educational needs.",
      })
    );

    // Clean up on unmount
    return () => {
      dispatch(resetMeta());
    };
  }, [dispatch]);
  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>About Us</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home </Link>
                  <span>About Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about__us spad">
        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: decode(setting?.about_us || ""),
          }}
        />
      </section>
    </>
  );
};

export default About;
