import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import { resetMeta, setMeta } from "../../reduxStore/Meta";

const Contact = () => {
  const axios = useAxios();
  const { setting } = useSelector((store) => store.Setting);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Set meta for Contact Page
    dispatch(
      setMeta({
        title: "Edutools.in - Contact Us",
        description:
          "Get in touch with us at Edutools.in. We are here to assist you with your queries and provide the best solutions for your educational needs.",
      })
    );

    // Clean up on unmount
    return () => {
      dispatch(resetMeta());
    };
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    setSuccess("");
    setError("");
    try {
      const response = await axios.post("/general/post_contact_us", formData);
      const result = response?.data;
      setSuccess(result?.message);
      setFormData({});
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      setError(errorMessage);
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Contact Us</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
              <div className="contact__widget">
                <span className="icon_phone"></span>
                <h4>Phone</h4>
                <p>+91 {setting?.telephone}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
              <div className="contact__widget">
                <span className="icon_pin_alt"></span>
                <h4>Address</h4>
                <p>{setting?.address}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
              <div className="contact__widget">
                <span className="icon_clock_alt"></span>
                <h4>Support</h4>
                <p>24/7 time</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 text-center">
              <div className="contact__widget">
                <span className="icon_mail_alt"></span>
                <h4>Email</h4>
                <p>{setting?.email}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49116.39176087041!2d-86.41867791216099!3d39.69977417971648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886ca48c841038a1%3A0x70cfba96bf847f0!2sPlainfield%2C%20IN%2C%20USA!5e0!3m2!1sen!2sbd!4v1586106673811!5m2!1sen!2sbd"
          height="500"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
        <div className="map-inside">
          <i className="icon_pin"></i>
          <div className="inside-widget">
            <h4>New York</h4>
            <ul>
              <li>Phone: +12-345-6789</li>
              <li>Add: 16 Creek Ave. Farmingdale, NY</li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="contact-form spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact__form__title">
                <h2>Leave Message</h2>
              </div>
            </div>
          </div>
          {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
           {success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  {success}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setSuccess("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  minLength={3}
                  maxLength={224}
                  required
                  value={formData?.name || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-6 col-md-6">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                  title="Please enter a valid email format, no double dots, and no trailing dot."
                  required
                  value={formData?.email || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-12 text-center">
                <textarea
                  placeholder="Your message"
                  name="message"
                  required
                  value={formData?.message || ""}
                  onChange={handleInputChange}
                ></textarea>
                <button type="submit" className="site-btn" disabled={busy}>
                  SEND MESSAGE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
