import React from "react";
import Banner1 from "../../img/banner/1.png";
import Banner2 from "../../img/banner/2.png";
const Banner = () => {
  return (
    <div className="banner" style={{marginBottom:'100px'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6" style={{margin:"10px 0px"}}>
            <div className="banner__pic">
              <img src={Banner1} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6" style={{margin:"10px 0px"}}>
            <div className="banner__pic">
              <img src={Banner2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
