import React, { useEffect, useState, useRef } from "react";
import HeroBanner from "../../img/hero/banner.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import { capitalizeWords, encryptId } from "../../utils/helpers";
import CryptoJS from "crypto-js";

const Hero = () => {
  const axios = useAxios();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { setting } = useSelector((state) => state.Setting);
  const [booksSubCategory, setBooksSubCategory] = useState([]);
  const [stationarySubCategory, setStationarySubCategory] = useState([]);
  const [category, setCategory] = useState("0");
  const [searchKey, setSearchKey] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [isCategoriesVisible, setIsCategoriesVisible] = useState(true);
  // const [expandedCategory, setExpandedCategory] = useState("");
  const [expandedCategory, setExpandedCategory] = useState("books");
  const searchResultsRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const secretKey = "12345";

  // Check if pathname is not "/"
  const isNotHomePage = location.pathname !== "/";

  // Function to toggle visibility
  const toggleCategories = () => {
    // if (isNotHomePage) {
    setIsCategoriesVisible((prevVisible) => !prevVisible);
    // }
  };

  const toggleSubCategory = (category) => {
    setExpandedCategory((prev) => (prev === category ? "" : category));
  };

  const handleSearchButton = (e) => {
    e.preventDefault();
    if (!searchKey) {
      return;
    }
    let sub_category_id = null;
    if (category === "0") {
      const result =
        booksSubCategory?.find((book) =>
          book?.category_name.includes(searchKey)
        ) ||
        stationarySubCategory?.find((item) =>
          item?.category_name.includes(searchKey)
        );
      if (result) sub_category_id = result.id;
    } else if (category === "1") {
      const result = booksSubCategory?.find((book) =>
        book?.category_name.includes(searchKey)
      );
      if (result) sub_category_id = result.id;
    } else if (category === "2") {
      const result = stationarySubCategory?.find((book) =>
        book?.category_name.includes(searchKey)
      );
      if (result) sub_category_id = result.id;
    }
    if (sub_category_id) {
      setIsSearched(true);
      const encryptedId = CryptoJS.AES.encrypt(
        sub_category_id.toString(),
        secretKey
      ).toString();
      navigate(`/shop-grid/${searchKey}/${encodeURIComponent(encryptedId)}`);
    } else if (results?.length) {
      const result = results[0];
      setIsSearched(true);
      const encryptedId = CryptoJS.AES.encrypt(
        result?.id.toString(),
        secretKey
      ).toString();
      navigate(
        `/shop-details/${result?.product_full_name?.replace(
          /[ /]+/g,
          "-"
        )}/${encodeURIComponent(encryptedId)}`
      );
    }
  };

  const handleSearch = async () => {
    // Build the query string manually
    let url = `/general/search?category=${category}`;
    if (searchKey) {
      url += `&search=${searchKey}`;
    }
    try {
      const response = await axios.get(url);
      const result = response?.data;
      setResults(result?.data);
    } catch (error) {}
  };

  const getAllSubCategories = async () => {
    try {
      const response = await axios.get("/general/all_sub_categories");
      const result = response?.data;
      const { books, stationary } = result?.data?.reduce(
        (acc, item) => {
          if (item.parent_category_id === 1) acc.books.push(item);
          else if (item.parent_category_id === 2) acc.stationary.push(item);
          return acc;
        },
        { books: [], stationary: [] }
      );

      setBooksSubCategory(books);
      setStationarySubCategory(stationary);
    } catch (error) {}
  };

  useEffect(() => {
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (searchKey) {
      handleSearch();
      // Cleanup the timeout when the component is unmounted or when the dependencies change
      return () => {};
    }
  }, [category, searchKey]);

  // Effect to hide categories when on the homepage
  // useEffect(() => {
  //   if (!isNotHomePage) {
  //     setIsCategoriesVisible(true);
  //   } else {
  //     setIsCategoriesVisible(false);
  //   }
  // }, [isNotHomePage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setIsSearched(true);
        // setIsCategoriesVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update screenWidth on window resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Show categories only if not on the home page and screen size is greater than a specific value (e.g., 768px)
    if (!isNotHomePage && screenWidth > 991) {
      setIsCategoriesVisible(true);
    } else {
      setIsCategoriesVisible(false);
    }
  }, [isNotHomePage, screenWidth]);

  return (
    <section className={`hero ${pathname !== "/" ? "hero-normal" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="hero__categories">
              <div className="hero__categories__all" onClick={toggleCategories}>
                <i className="fa fa-bars"></i>
                <span>All Categories</span>
              </div>

              <ul style={{ display: isCategoriesVisible ? "block" : "none" }}>
                {/* Books Category */}
                <li
                  onClick={() => toggleSubCategory("books")}
                  style={{ padding: "0px 0px 0px 20px" }}
                >
                  <a
                    href="#"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <strong>Books</strong>
                    <div>
                      <i
                        className="fa fa-angle-down"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </div>
                  </a>
                </li>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowX: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {expandedCategory === "books" &&
                    booksSubCategory?.map((bookSubCat) => (
                      <li
                        key={bookSubCat.id}
                        onClick={() => {
                          // Encrypt the bookSubCat.id
                          const encryptedId = encryptId(
                            bookSubCat?.id,
                            secretKey
                          );

                          // Hide the categories list and navigate with the encrypted ID
                          setIsCategoriesVisible(false);
                          navigate(
                            `/shop-grid/${
                              bookSubCat?.url_key
                            }/${encodeURIComponent(encryptedId)}`
                          );
                        }}
                      >
                        <span>
                          {capitalizeWords(bookSubCat.category_name || "")}
                        </span>
                      </li>
                    ))}
                </div>
                <hr />
                {/* Stationery Category */}
                <li
                  onClick={() => toggleSubCategory("stationary")}
                  style={{ padding: "0px 0px 0px 20px" }}
                >
                  <a
                    href="#"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <strong>Stationery</strong>
                    <div>
                      <i
                        className="fa fa-angle-down"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </div>
                  </a>
                </li>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowX: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {expandedCategory === "stationary" &&
                    stationarySubCategory?.map((stationarySubCat) => (
                      <li
                        key={stationarySubCat.id}
                        onClick={() => {
                          const encryptedId = CryptoJS.AES.encrypt(
                            stationarySubCat?.id.toString(),
                            secretKey
                          ).toString();
                          setIsCategoriesVisible(false);
                          navigate(
                            `/shop-grid/${
                              stationarySubCat?.url_key
                            }/${encodeURIComponent(encryptedId)}`
                          );
                        }}
                      >
                        <span>
                          {capitalizeWords(
                            stationarySubCat.category_name || ""
                          )}
                        </span>
                      </li>
                    ))}
                </div>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="hero__search">
              <div className="hero__search__form">
                <form onSubmit={handleSearchButton}>
                  <div className="hero__search__categories">
                    <select
                      name="category"
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setSearchKey("");
                      }}
                    >
                      <option value="0">All Categories</option>
                      <option value="1">Books</option>
                      <option value="2">Stationery</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    placeholder="What do yo u need?"
                    onChange={(e) => {
                      setIsSearched(false);
                      setSearchKey(e.target.value);
                    }}
                    value={searchKey || ""}
                    onFocus={() => setIsSearched(false)}
                  />
                  <button
                    type="submit"
                    className="site-btn"
                    onClick={handleSearchButton}
                  >
                    SEARCH
                  </button>
                </form>
              </div>
              {!!searchKey && !isSearched ? (
                <div className="search_result" ref={searchResultsRef}>
                  <ul>
                    {results?.map((result, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsCategoriesVisible(false);
                          setIsSearched(true);
                          setSearchKey(result?.category_name);
                          const encryptedId = CryptoJS.AES.encrypt(
                            result?.id.toString(),
                            secretKey
                          ).toString();
                          navigate(
                            result?.category_name
                              ? `/shop-grid/${result?.category_name?.replace(
                                  /[ /]+/g,
                                  "-"
                                )}/${encodeURIComponent(encryptedId)}`
                              : `/shop-details/${result?.product_full_name?.replace(
                                  /[ /]+/g,
                                  "-"
                                )}/${encodeURIComponent(encryptedId)}`
                          );
                        }}
                      >
                        <i className="fa fa-search"></i>&nbsp;&nbsp;&nbsp;
                        <span>
                          {result?.category_name && (
                            <b style={{ fontWeight: "500px" }}>
                              {result.category_name}
                            </b>
                          )}{" "}
                          {/* Displays category_name separately */}
                          {[
                            result?.product_name,
                            result?.subject,
                            result?.language,
                            result?.writer,
                            result?.board,
                          ]
                            .filter((item) => item) // Filters out any undefined or empty fields
                            .join(" | ")}{" "}
                          {/* Joins available fields with " | " separator */}
                        </span>
                      </li>
                    ))}
                    {results?.length === 0 ? (
                      <li>
                        <span>No results found</span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div className="hero__search__phone">
                <div className="hero__search__phone__icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="hero__search__phone__text">
                  <h5>+91 {setting?.telephone}</h5>
                  <span>Support 24/7 time</span>
                </div>
              </div>
            </div>
            {pathname === "/" && (
              <div
                className="hero__item set-bg"
                style={{ backgroundImage: `url(${HeroBanner})` }}
              >
                <div className="hero__text">
                  <span>BOOKS</span>
                  <h2>
                    Books <br />
                    100% Originals
                  </h2>
                  <p>Free Pickup and Delivery Available</p>
                  <a href="#" className="primary-btn">
                    SHOP NOW
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
