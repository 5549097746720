import CryptoJS from 'crypto-js';

// Function to capitalize words
const capitalizeWords = (str) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

// Function to calculate base price
const basePrice = (product, quantity = 1) => {
  const {
    tax_include,
    product_price,
    tax_percentage,
    product_discount_type,
    product_discount,
  } = product;

  const price = parseFloat(product_price) || 0;
  const taxRate = (parseFloat(tax_percentage) || 0) / 100;
  const discountVal = parseFloat(product_discount) || 0;

  const rate = Number(tax_include) === 1 ? price / (1 + taxRate) : price;
  const discountAmount =
    Number(product_discount_type) === 1
      ? discountVal
      : (rate * discountVal) / 100;

  const net = parseFloat(rate - discountAmount).toFixed(2);
  const netTaxableValue = parseFloat(net * 1).toFixed(2);
  const taxAmount = parseFloat(netTaxableValue * taxRate).toFixed(2);

  const mainRowAmount = parseFloat(
    (parseFloat(taxAmount) + parseFloat(netTaxableValue)) * quantity
  ).toFixed(2);

  return parseFloat(mainRowAmount).toFixed(2);
};

// Function to encrypt ID using AES encryption
const encryptId = (id, secretKey) => {
  return CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
};

export { basePrice, capitalizeWords, encryptId };
