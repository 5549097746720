import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";

const AuthProtectedRoute = ({ isLoggedIn, children, loading }) => {
  if (isLoggedIn === undefined || isLoggedIn === null || loading) {
    // Render a loading spinner or component when loading is true
    return (
      <div style={{ padding: "20vh 0" }}>
        <Loader />
      </div>
    );
  } // If the user is logged in, redirect to the home page or a dashboard
  return isLoggedIn ? <Navigate to="/" replace /> : children;
};

export default AuthProtectedRoute;
