import React from "react";
import { useNavigate } from "react-router-dom";
import pagenotfound from "../../img/pagenotfound.png";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/"); // Redirect to the home page
  };

  return (
    <section className="about__us spad">
      <div className="container">
        <div style={{textAlign:'center'}}>
            <img style={{width:'300px', height:''}} alt="Page not Found" src={pagenotfound}></img>
        <h3>
          Unfortunately the page you are looking for has been moved or deleted
        </h3>
        <button onClick={goToHome} className="btn notfound-btn" style={{marginTop:'50px'}}>
          Go to HomePage
        </button>
      </div>
      </div>
    </section>
  );
};

export default PageNotFound;
