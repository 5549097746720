import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Edutools.in",
  description: "Edutools",
};

const Meta = createSlice({
  name: "Meta",
  initialState,
  reducers: {
    setMeta: (state, action) => {
      const { title, description } = action.payload;
      if (title) state.title = title;
      if (description) state.description = description;
    },
    resetMeta: (state) => {
      state.title = "Edutools.in";
      state.description = "Edutools";
    },
  },
});

export const { setMeta, resetMeta } = Meta.actions;
export default Meta.reducer;
