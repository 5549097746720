import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import NoImage from "../../img/product_no_image.png";
import { initialCartState } from "../../reduxStore/Cart";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader";
import Logout from "./Logout";
import { capitalizeWords, encryptId } from "../../utils/helpers";
import CryptoJS from "crypto-js";

function OrderDetails() {
  const { id } = useParams(); // Get the `id` from the URL params
  const axios = useAxios();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [cancelledReason, setCancelledReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const secretKey = "12345";

  const [decryptedId, setDecryptedId] = useState(null);

  useEffect(() => {
    if (id) {
      const bytes = CryptoJS.AES.decrypt(id, secretKey);
      const originalId = bytes.toString(CryptoJS.enc.Utf8);

      if (originalId) {
        setDecryptedId(originalId);
      } else {
        console.error("Decryption failed!");
      }
    }
  }, [id]);

  // Fetch order details based on the `id` from params
  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`/order/get_order_by_id/${decryptedId}`);
      if (response?.data?.success) {
        const orderData = response?.data?.data;
        setOrderDetails(orderData);
        setOrderItems(orderData?.order_items || []);
        setClientDetails(orderData?.checkout_form_data || {});
        setInvoiceId(orderData?.invoice_id);
        setInvoiceNo(orderData?.invoice_number);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    if (decryptedId) {
      fetchOrderDetails();
    }
  }, [decryptedId]);

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  const handlePaymentClick = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    try {
      // Get the order details
      const response = await axios.get(`/order/get_order_by_id/${decryptedId}`);
      if (response?.data?.success) {
        const orderData = response?.data?.data;

        // Get the required details for Razorpay
        setButtonDisabled(false);
        const paymentData = {
          key: "rzp_test_6MTLjFzCfZKHOk", // Replace with your Razorpay key
          amount: orderData?.amount * 100, // Razorpay expects the amount in paise
          currency: "INR",
          name: "Your Company Name",
          description: `Order: ${orderData?.order_number}`,
          image: "https://yourcompanylogo.com/logo.png", // Optional: Add your logo
          order_id: orderData?.transaction_order_number, // Pass the Razorpay order number
          handler: async function (response) {
            // Handle successful payment
            setLoading(true);
            try {
              const verification = await axios.post(
                "/order/payment_varification",
                {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                }
              );
              setLoading(false);
              toast("Payment verified successfully");
              getCartItems();
              navigate("/profile/orders");
              // window.location.reload();
            } catch (verificationError) {
              console.error("Payment verification failed", verificationError);
              toast("Payment verified failed");
              setLoading(false);
              setButtonDisabled(false);
            }
          },
          prefill: {
            name: orderData?.checkout_form_data?.client_name,
            email: orderData?.checkout_form_data?.client_email,
            contact: orderData?.checkout_form_data?.client_phone,
          },
          notes: {
            order_id: orderData?.id,
          },
        };

        const razorpay = new window.Razorpay(paymentData);
        razorpay.open();
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setError("Failed to place order. Please try again.");
      setButtonDisabled(false);
    }
  };

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.edutools.in/order/invoice_pdf/${invoiceId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download the PDF");
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `invoice_${invoiceNo}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async () => {
    if (!cancelledReason) alert("Cancel reason required");
    setLoading(true);
    try {
      const response = await axios.post(
        `/order/cancel_order/${orderDetails?.order_number}`,
        {
          cancelled_reason: cancelledReason,
        }
      );
      const result = response.data;
      setCancelledReason("");
      fetchOrderDetails();
      toast("Cancelled Order");
      closeModal();
    } catch (error) {
      toast(error?.response?.data?.message || "Please try after some time");
    } finally {
      setLoading(false);
    }
  };

  if (!orderDetails) {
    return (
      <div style={{ padding: "20vh 0" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="section content">
      {loading && <Loader />}
      <div className="container">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold heading">Order Details</h4>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-3">
            <ul className="list-group tabcss">
              <Link to="/profile">
                <li className="list-group-item">
                  <i
                    className="fa fa-user-circle-o"
                    style={{ marginRight: "5px" }}
                  ></i>
                  My Profile
                </li>
              </Link>
              <Link to="/profile/favourite">
                <li className="list-group-item">
                  <i className="fa fa-heart" style={{ marginRight: "5px" }}></i>
                  Wishlist
                </li>
              </Link>
              <Link to="/profile/orders">
                <li className="list-group-item active1">
                  <i
                    className="fa fa-shopping-bag"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Orders
                </li>
              </Link>
              <Logout />
            </ul>
          </div>

          <div className="col-lg-9">
            <div className="mt-4">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5 className="mb-2">
                    <b>Client Details</b>
                  </h5>
                  <p>
                    <strong>
                      {capitalizeWords(clientDetails?.client_name || "")}
                    </strong>
                  </p>
                  <p>{clientDetails?.client_email}</p>
                  <p>{clientDetails?.client_phone}</p>
                  <p>
                    <strong></strong>{" "}
                    {capitalizeWords(clientDetails?.client_address || "")},{" "}
                    {clientDetails?.client_city}, {clientDetails?.client_state}
                  </p>
                  <b>Order Details</b>

                  <p style={{ marginTop: "10px" }}>
                    Order Id: {orderDetails?.order_number}
                  </p>
                  <p>Total Amount: {orderDetails?.amount}</p>
                </div>
                {!invoiceId && orderDetails.payment_status === "captured" ? (
                  <div>
                    <p style={{ color: "tomato" }}>
                      Your invoice has not been created. Please{" "}
                      <Link to="/contact" style={{ color: "blue" }}>
                        contact the admin.
                      </Link>
                    </p>
                  </div>
                ) : null}
                {orderDetails?.is_cancelled === 1 ? (
                  <span style={{ color: "tomato" }}>
                    Order has been Cancelled !
                  </span>
                ) : (
                  <div>
                    {invoiceId ? (
                      <button
                        className="btn"
                        style={{ background: "#f2f2f2", width: "95px" }}
                        onClick={downloadPdf}
                      >
                        {" "}
                        <i className="fa fa-download" aria-hidden="true"></i>
                        &nbsp; Invoice
                      </button>
                    ) : null}

                    {invoiceId ? (
                      <div>
                        <button
                          className="btn btn-warning"
                          style={{
                            color: "#fff",
                            marginTop: "10px",
                            width: "95px",
                          }}
                          type="button"
                          disabled={loading}
                          onClick={openModal}
                        >
                          {loading ? "Processing..." : "Cancel"}
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {orderDetails?.payment_status !== "captured" ? (
                    <div>
                      <button
                        onClick={handlePaymentClick}
                        className="btn"
                        style={{
                          background: "#7fad39",
                          color: "#fff",
                          // width: "100px",
                        }}
                        disabled={buttonDisabled}
                      >
                        {loading ? "Processing..." : "Pay Now"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <h5 className="mb-3 mt-3">Items in this Order</h5>
              {orderItems.length > 0 ? (
                <div>
                  {orderItems.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        const encryptedId = encryptId(item?.id, secretKey);
                        navigate(
                          `/shop-details/${item?.product_name?.replace(
                            /[ /]+/g,
                            "-"
                          )}/${encodeURIComponent(encryptedId)}`
                        );
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px",
                        margin: "10px",
                        background: "#fff",
                        borderRadius: "6px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            item.base_image_url || NoImage
                          })`,
                          width: "70px",
                          height: "70px",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          borderRadius: "2px",
                        }}
                      ></div>
                      <div style={{ flex: 1, marginLeft: "10px" }}>
                        <p style={{ margin: "5px 0" }}>
                          {capitalizeWords(item.product_name || "")}
                        </p>
                        <p style={{ margin: "5px 0" }}>Qty: {item.quantity}</p>
                        <p style={{ margin: "5px 0" }}>
                          ₹{" "}
                          {parseFloat(
                            Number(item.mainRowAmount || 0) /
                              Number(item.quantity || 1)
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No items in this order.</p>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
        {isModalOpen && (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ padding: "0px" }}>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="cancelledReason"
                        className="col-form-label"
                      >
                        Reason for Cancelling the Order:
                      </label>
                      <textarea
                        className="form-control"
                        id="cancelledReason"
                        value={cancelledReason}
                        onChange={(e) => setCancelledReason(e.target.value)}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                    style={{
                      background: "rgb(242, 242, 242)",
                      color: "black",
                      border: "none",
                    }}
                  >
                    Close
                  </button>
                  <button
                    onClick={cancelOrder}
                    type="button"
                    className="btn btn-primary"
                    style={{ padding: "0.375rem 0.75rem" }}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
