import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import NoImage from "../../img/product_no_image.png";
import Breadcrumb from "../../img/breadcrumb.jpg";
import useAxios from "../../utils/axiosInstance";
import { basePrice, capitalizeWords, encryptId } from "../../utils/helpers";
import "react-range-slider-input/dist/style.css"; // Import styles for the slider
import RangeSlider from "react-range-slider-input";
import { useDispatch } from "react-redux";
import { resetMeta, setMeta } from "../../reduxStore/Meta";
import Loader from "../../components/Loader";
import CryptoJS from "crypto-js";
import noimagefound from "../../img/no-products-found.jpg";
import CustomPagination from "../../components/customPagination/CustomPagination";

const ShopGrid = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const { category_name, category_id } = useParams();
  const [decryptedId, setDecryptedId] = useState(null);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [description, setDescription] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [backgroundImages, setBackgroundImages] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [perPage, setPerPage] = useState(12);
  const [totalPage, setTotalPage] = useState(0); // Ensure it's a number, not an
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const maincat = queryParams.get("cat");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const filterRef = useRef(null);

  const secretKey = "12345";
  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    if (decryptedId) {
      getCategoryProducts(currentPage); // Ensure you're passing the correct page
    }
  }, [decryptedId, currentPage]); // Make sure currentPage is a dependency

  useEffect(() => {
    if (category_id) {
      try {
        const bytes = CryptoJS.AES.decrypt(category_id, secretKey);
        const originalId = bytes.toString(CryptoJS.enc.Utf8);

        if (originalId) {
          setDecryptedId(originalId);
        } else {
          console.error("Decryption failed!");
          setLoading(false);
        }
      } catch (error) {
        console.error("Decryption error:", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [category_id]);

  const getCategoryProducts = async (page) => {
    setLoading(true);
    try {
      // Ensure page is correctly passed
      let url = `/products/get_product_by_subCategory?category_id=${decryptedId}&page=${page}`;
      if (maincat !== null) {
        url += `&cat=${maincat}`;
      }
      const response = await axios.get(url);
      const result = response?.data?.data || [];
      const result1 = response?.data?.categoryData.category_description || [];
      const totalPage = response?.data?.pagination.totalPages || 0;
      setPerPage(response?.data?.pagination?.perPage || 12);
      setTotalPage(totalPage); // Ensure totalPages is set properly
      setDescription(result1);

      setProducts(result);
      extractFilters(result);
      const max = Math.max(...result.map((p) => p.product_price || 0), 0);
      setMaxPrice(max);
      setPriceRange([0, max]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  const visibleFilters = {
    product_categories_names: "Product Categories",
    board: "Board",
    ink_color: "Ink Color",
    language: "Language",
    no_of_pages: "No of Pages",
    product_back_cover: "Back Cover",
    product_class_grade: "Class Grade",
    product_color: "Color",
    company_name: "Company Name",
    product_edition: "Edition",
    product_packer: "Packer",
    product_size: "Size",
    product_unit_of_measurement: "Unit of Measurement",
    ruling: "Ruling",
    subject: "Subject",
    variation_options: "Variation Options",
    writer: "Writer",
  };

  const extractFilters = (products) => {
    const filters = {};
    products.forEach((product) => {
      for (const key in product) {
        if (product[key] && Object.keys(visibleFilters).includes(key)) {
          if (!filters[key]) filters[key] = new Set();

          if (
            typeof product[key] === "string" &&
            product[key].startsWith("[") &&
            product[key].endsWith("]")
          ) {
            try {
              const parsedCategories = JSON.parse(product[key]);
              parsedCategories.forEach((category) =>
                filters[key].add(category)
              );
            } catch (e) {
              console.error("Error parsing categories:", e);
            }
          } else {
            filters[key].add(product[key]);
          }
        }
      }
    });

    if (filters["product_categories_names"]) {
      filters["product_categories_names"] = new Set(
        [...filters["product_categories_names"]].sort((a, b) =>
          a.localeCompare(b)
        )
      );
    }

    Object.keys(filters).forEach((key) => {
      filters[key] = new Set(
        [...filters[key]].sort((a, b) => a.localeCompare(b))
      );
    });

    setFilters(filters);
  };

  const handleMultiSelectChange = (key, value) => {
    setSelectedFilters((prev) => {
      const currentValues = prev[key] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      return {
        ...prev,
        [key]: updatedValues,
      };
    });
  };

  const applyFilters = () => {
    return products
      .filter((product) => {
        return Object.entries(selectedFilters).every(([key, values]) => {
          if (key === "product_categories_names") {
            if (values.length === 0) return true; // No filter on categories
            const productCategories = JSON.parse(product[key] || "[]");
            return values.some((value) => productCategories.includes(value));
          }
          return values.length ? values.includes(product[key]) : true;
        });
      })
      .filter(
        (product) =>
          product.product_price >= priceRange[0] &&
          product.product_price <= priceRange[1]
      );
  };

  const updatePaginatedProducts = () => {
    const filtered = applyFilters(products);

    setFilteredProduct(filtered);
  };

  useEffect(() => {
    if (products.length > 0) {
      updatePaginatedProducts();
    }
  }, [products, selectedFilters, priceRange, currentPage]); // Apply filters and pagination when these states change

  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  useEffect(() => {
    dispatch(
      setMeta({
        title: category_name
          ?.split(/[-_]/)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        description: description,
      })
    );
    return () => {
      dispatch(resetMeta());
    };
  }, [dispatch, category_name, description]);

  useEffect(() => {
    if (!products.length && !loading && decryptedId === null) {
      navigate("/");
    }
  }, [products, loading, decryptedId, navigate]);

  useEffect(() => {
    // Reset filters when category_id or maincat changes
    setSelectedFilters({});
    setPriceRange([0, maxPrice]); // Optional: Reset the price range to default
    // setCurrentPage(1);
  }, [category_id, maincat, maxPrice]);

  useEffect(() => {
    setCurrentPage(1);
  }, [category_id, maincat]);

  const handlePageChange = (e, p) => {
    setCurrentPage(p); // Update the page number
  };

  return (
    <>
      {loading && <Loader />}

      {/* Modal for Filters (Only visible on mobile) */}
      {showFilterModal && (
        <div className="filter-modal">
          <div className="filter-modal-content" ref={filterRef}>
            <div className="filter-modal-header">
              <h5>Filter Options</h5>
              <button
                onClick={() => setShowFilterModal(false)}
                className="close-modal-btn"
              >
                x
              </button>
            </div>

            {/* Price Filter */}
            <div className="sidebar__item">
              <h5 style={{ paddingBottom: "10px", fontWeight: "800" }}>
                Price Range
              </h5>
              <RangeSlider
                className="range-slider"
                min={0}
                max={maxPrice}
                step={1}
                value={priceRange}
                onInput={handlePriceChange}
              />
              <div style={{ marginTop: "15px" }}>
                Min ₹{priceRange[0]}{" "}
                <span style={{ marginLeft: "30px" }}>Max ₹{priceRange[1]}</span>
              </div>
            </div>

            {/* Filters Content */}
            <div className="modal-filters">
              {Object.entries(filters).map(([key, values]) => (
                <div className="sidebar__item" key={key}>
                  <span style={{ fontSize: "18px", fontWeight: "800" }}>
                    {visibleFilters[key]}
                  </span>
                  <div
                    className="multi-select"
                    style={{
                      maxHeight: "120px",
                      overflowX: "auto",
                      marginTop: "10px",
                    }}
                  >
                    {key === "product_categories_names"
                      ? [...values].map((value) => (
                          <label key={value}>
                            <input
                              type="checkbox"
                              value={value}
                              onChange={() =>
                                handleMultiSelectChange(key, value)
                              }
                              checked={
                                selectedFilters[key]?.includes(value) || false
                              }
                            />
                            {capitalizeWords(value || "")}
                          </label>
                        ))
                      : [...values].map((value) => (
                          <label key={value}>
                            <input
                              type="checkbox"
                              value={value}
                              onChange={() =>
                                handleMultiSelectChange(key, value)
                              }
                              checked={
                                selectedFilters[key]?.includes(value) || false
                              }
                            />
                            {capitalizeWords(value || "")}
                          </label>
                        ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>
                  {category_name
                    ?.split(/[-_]/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>
                    {category_name
                      ?.split(/[-_]/)
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-5">
              <div className="sidebar hide-on-mobile">
                <div className="sidebar__item">
                  <h5 style={{ paddingBottom: "10px", fontWeight: "800" }}>
                    Price Range
                  </h5>
                  <RangeSlider
                    className="range-slider"
                    min={0}
                    max={maxPrice}
                    step={1}
                    value={priceRange}
                    onInput={handlePriceChange}
                    style={{ color: "red" }}
                  />
                  <div style={{ marginTop: "15px" }}>
                    Min ₹{priceRange[0]}{" "}
                    <span style={{ marginLeft: "30px" }}>
                      Max- ₹{priceRange[1]}
                    </span>
                  </div>
                </div>
                {Object.entries(filters).map(([key, values]) => (
                  <div className="sidebar__item" key={key}>
                    <span style={{ fontSize: "18px", fontWeight: "800" }}>
                      {visibleFilters[key]}
                    </span>
                    <div
                      className="multi-select"
                      style={{
                        maxHeight: "120px",
                        overflowX: "auto",
                        marginTop: "10px",
                      }}
                    >
                      {key === "product_categories_names"
                        ? [...values].map((value) => (
                            <label key={value}>
                              <input
                                type="checkbox"
                                value={value}
                                onChange={() =>
                                  handleMultiSelectChange(key, value)
                                }
                                checked={
                                  selectedFilters[key]?.includes(value) || false
                                }
                              />
                              {capitalizeWords(value || "")}
                            </label>
                          ))
                        : [...values].map((value) => (
                            <label key={value}>
                              <input
                                type="checkbox"
                                value={value}
                                onChange={() =>
                                  handleMultiSelectChange(key, value)
                                }
                                checked={
                                  selectedFilters[key]?.includes(value) || false
                                }
                              />
                              {capitalizeWords(value || "")}
                            </label>
                          ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-9 col-md-7">
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="filter-btn show-on-mobile"
                  onClick={() => setShowFilterModal(true)}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i> Filter
                </button>
              </div>
              <div className="row">
                {filteredProduct?.length > 0 ? (
                  filteredProduct?.map((product) => (
                    <div
                      key={product?.id}
                      className="col-lg-3 col-md-6 col-sm-6 col-6"
                    >
                      <div className="product__item">
                        <div
                          className="product__item__pic set-bg no__cover"
                          style={{
                            cursor: "pointer",
                            backgroundImage: `url(${
                              product.base_image_url
                                ? product.base_image_url
                                : NoImage
                            })`,
                          }}
                          onClick={() => {
                            const encryptedId = encryptId(
                              product?.id,
                              secretKey
                            );
                            navigate(
                              `/shop-details/${product?.product_full_name?.replace(
                                /[ /]+/g,
                                "-"
                              )}/${encodeURIComponent(encryptedId)}`
                            );
                          }}
                        ></div>
                        <div className="product__item__text">
                          <h6>
                            {capitalizeWords(product?.product_name || "")}
                          </h6>
                          <h5>₹ {basePrice(product, 1)}</h5>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <div className="col-12">
                      <img
                        src={noimagefound}
                        style={{
                          width: "300px",
                          height: "300px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
              {!loading && filteredProduct?.length ? (
                <CustomPagination
                  count={totalPage}
                  page={currentPage}
                  length={products?.length}
                  currentDataLength={filteredProduct?.length}
                  onChange={handlePageChange}
                  perPage={perPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopGrid;
