import React from "react";
import Humberger from "../../components/humberger/Humberger";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import Categories from "../../components/categories/Categories";
import Featured from "../../components/featured/Featured";
import Banner from "../../components/banner/Banner";
import Latest from "../../components/latest/Latest";
import Blog from "../../components/blog/Blog";
import CategorySlider from "../../components/slider/Slider";
import Loader from "../../components/Loader";
import CustomArrows from "../../components/slider/CustomArrows";

const Home = () => {
  return (
    <>
      {/* <Categories /> */}
      <CategorySlider />
      {/* <CustomArrows/> */}
      <Featured />
      <Banner />
      {/* <Loader/> */}
      {/* <Latest /> */}
      {/* <Blog /> */}
    </>
  );
};

export default Home;
