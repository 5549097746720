import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/axiosInstance";
import { useDispatch } from "react-redux";
import { login } from "../../../reduxStore/Auth";

const Login = () => {
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get("redirect");
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    setBusy(true);
    try {
      const response = await axios.post("/auth/login", formData);
      const result = response?.data;
      setFormData({});
      if (redirect) {
        navigate(`/${redirect}`);
      } else {
        navigate("/"); // fallback to home or another default page
      }
      localStorage.setItem("token", result?.token);
      dispatch(login({ user: result?.data }));
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Something went wrong! Please try again";
      setError(errorMessage);
    } finally {
      setBusy(false);
    }
  };
  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-md-12 contents">
            <div className="row justify-content-center">
              <div className="col-md-8 col-xl-6">
                <div className="mb-4 mt-4">
                  <h3>
                    Login In to <span className="fw-bold">Edutools</span>
                  </h3>
                </div>
                <form onSubmit={handleSubmit}>
                  {error && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      {error}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setError("")}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <div className="form-group first">
                    <label htmlFor="client_username">Username / Email *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="client_username"
                      name="client_username"
                      required
                      value={formData?.client_username || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          client_username: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group last mb-4">
                    <label htmlFor="password">Password *</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      required
                      value={formData?.password || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                  </div>

                  <div className="d-flex mb-5 align-items-center">
                    {/* <label className="control control--checkbox mb-0">
                      <input type="checkbox" />
                      <span className="caption">{"   "}Remember me</span>
                    </label> */}
                    <span className="ml-auto login_forgot">
                      <Link to={"/auth/forgot"}>Forgot Password</Link>
                    </span>
                  </div>

                  <input
                    type="submit"
                    value="Log In"
                    className="btn text-white btn-block btn-success"
                    disabled={busy}
                  />
                  <span className="login_forgot">
                    <Link
                      to="/auth/register"
                      className="d-block text-left my-4 "
                    >
                      {" "}
                      Create Account to Edutools
                    </Link>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
