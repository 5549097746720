import { useDispatch, useSelector } from "react-redux";
import { initialSettingState } from "./reduxStore/Setting";
import Router from "./routes/Router";
import useAxios from "./utils/axiosInstance";
import { useEffect } from "react";
import { resetMeta, setMeta } from "./reduxStore/Meta";
function App() {
  
  const dispatch = useDispatch();
  const axios = useAxios();
  const { title, description } = useSelector((state) => state.Meta);

  const getSettings = async () => {
    try {
      const response = await axios.get("/general/settings");
      const result = response?.data;
      dispatch(initialSettingState({ setting: result.data }));
    } catch (error) {}
  };

  useEffect(() => {
    getSettings();
  }, []);



  useEffect(() => {
    // Update document title and meta description dynamically
    document.title = title;

    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }
  }, [title, description]);
  return <Router />;
}

export default App;
