import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { removeAllCartItems } from "../../reduxStore/Cart";
import { removeWishlist } from "../../reduxStore/Wishlist";
import { removeLoggedInInfo } from "../../reduxStore/Auth";
import { capitalizeWords, encryptId } from "../../utils/helpers";

const Humberger = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.Setting);
  const { isLoggedIn, user } = useSelector((state) => state.Auth);
  const { cartLength, total } = useSelector((state) => state.Cart);
  const { wishlistLength } = useSelector((state) => state.Wishlist);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menuWrapperRef = useRef(null);
  const { pathname } = location;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const closeMenu = () => {
    if (menuWrapperRef.current) {
      menuWrapperRef.current.classList.remove("show__humberger__menu__wrapper");
      document
        .querySelector(".humberger__menu__overlay")
        .classList.remove("active");
      document.body.classList.remove("over_hid");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    dispatch(removeAllCartItems());
    dispatch(removeWishlist());
    dispatch(removeLoggedInInfo());
    closeMenu();
  };

  const secretKey = "12345";

  const encryptedId = encryptId(1, secretKey);
  const stationaryencryptedId = encryptId(2, secretKey);

  return (
    <>
      <div className="humberger__menu__overlay" onClick={closeMenu}></div>
      <div className="humberger__menu__wrapper" ref={menuWrapperRef}>
        <div className="humberger__menu__logo">
          <Link to="/" onClick={closeMenu}>
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="humberger__menu__cart">
          <ul>
            <li>
              <Link to="/wish-list" onClick={closeMenu}>
                <i className="fa fa-heart"></i> <span>{wishlistLength}</span>
              </Link>
            </li>
            <li>
              <Link to="/shopping-cart" onClick={closeMenu}>
                <i className="fa fa-shopping-bag"></i> <span>{cartLength}</span>
              </Link>
            </li>
          </ul>
          <div className="header__cart__price">
            item: <span>₹&nbsp;{total}</span>
          </div>
        </div>
        <div className="" style={{ borderBottom: "1px solid #e1e1e1" }}>
          <div className="header__top__right__auth" ref={dropdownRef}>
            {isLoggedIn ? (
              <div
                className="nav-item dropdown"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="nav-link dropdown-toggle"
                  role="button"
                  style={{ fontSize: "18px", padding: "10px 0px" }}
                >
                  <i className="fa fa-user" style={{ marginRight: "5px" }}></i>
                  {user?.client_name?.length > 15
                    ? capitalizeWords(`${user.client_name.slice(0, 15)}...`)
                    : capitalizeWords(user.client_name)}
                </span>
                {dropdownOpen && (
                  <ul
                    className="header__menu__dropdown"
                    style={{ textDecoration: "none", listStyle: "none" }}
                  >
                    <li className="active">
                      <Link
                        className="dropdown-item"
                        to="/profile"
                        onClick={closeMenu}
                      >
                        <i
                          className="fa fa-user-circle-o"
                          style={{ marginRight: "5px" }}
                        ></i>
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/profile/favourite"
                        onClick={closeMenu}
                      >
                        <i
                          className="fa fa-heart"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Wishlist
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/profile/orders"
                        onClick={closeMenu}
                      >
                        <i
                          className="fa fa-shopping-bag"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Orders
                      </Link>
                    </li>
                    <li onClick={logoutHandler}>
                      <span className="dropdown-item" style={{ color: "red" }}>
                        <i
                          className="fa fa-sign-out"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Logout
                      </span>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              <ul className="slicknav_nav slicknav_hidden">
                <li className={`${pathname === "/auth/login" ? "active" : ""}`}>
                  <Link
                    to="/auth/login"
                    onClick={closeMenu}
                    style={{
                      fontSize: "18px",
                      padding: "10px 0px",
                      border: "none",
                    }}
                  >
                    <i className="fa fa-user"></i> Login
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="slicknav_menu">
          <nav
            className="slicknav_nav slicknav_hidden"
            aria-hidden="true"
            role="menu"
          >
            <ul>
              <li className={`${pathname === "/" ? "active" : ""}`}>
                <Link to="/" role="menuitem" onClick={closeMenu}>
                  <i className="fa fa-home" style={{ marginRight: "5px" }}></i>
                  Home
                </Link>
              </li>
              <li
                className={`${
                  pathname.startsWith("/shop-grid/books/") &&
                  new URLSearchParams(window.location.search).get("cat") ===
                    "maincat"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={`/shop-grid/books/${encodeURIComponent(
                    encryptedId
                  )}?cat=maincat`}
                  onClick={closeMenu}
                >
                  <i className="fa fa-book" style={{ marginRight: "5px" }}></i>
                  Books
                </Link>
              </li>
              <li
                className={`${
                  pathname.startsWith("/shop-grid/stationery/") &&
                  new URLSearchParams(window.location.search).get("cat") ===
                    "maincat"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={`/shop-grid/stationery/${encodeURIComponent(
                    stationaryencryptedId
                  )}?cat=maincat`}
                  onClick={closeMenu}
                >
                  <i
                    className="fa fa-pencil"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Stationery
                </Link>
              </li>
              <li
                className={`${pathname === "/shopping-cart" ? "active" : ""}`}
              >
                <Link to="/shopping-cart" role="menuitem" onClick={closeMenu}>
                  <i
                    className="fa fa-shopping-cart"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Cart
                </Link>
              </li>
              <li className={`${pathname === "/contact" ? "active" : ""}`}>
                <Link to="/contact" role="menuitem" onClick={closeMenu}>
                  <i
                    className="fa fa-phone-square"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header__top__right__social">
          <a target="_blank" href={setting?.facebook_link}>
            <i className="fa fa-facebook"></i>
          </a>
          <a target="_blank" href={setting?.instagram_link}>
            <i className="fa fa-instagram"></i>
          </a>
          <a target="_blank" href={setting?.twitter_link}>
            <i className="fa fa-twitter"></i>
          </a>
        </div>
        <div className="humberger__menu__contact">
          <ul>
            <li>
              <i className="fa fa-envelope"></i> {setting?.email}
            </li>
            <li>Free Shipping for all Order of ₹499</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Humberger;
