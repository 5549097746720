import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { removeAllCartItems } from "../../reduxStore/Cart";
import { removeWishlist } from "../../reduxStore/Wishlist";
import { removeLoggedInInfo } from "../../reduxStore/Auth";
import Loader from "../../components/Loader";

function Logout() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const logoutHandler = () => {
    setLoading(true);
    localStorage.removeItem("token");
    dispatch(removeAllCartItems());
    dispatch(removeWishlist());
    dispatch(removeLoggedInInfo());
    setLoading(false);
  };

  return (
    <>
     {loading && <Loader />}
      <li className="list-group-item" onClick={logoutHandler}>
        <span>
          <i className="fa fa-sign-out" style={{ marginRight: "5px" }}></i>
          Logout
        </span>
      </li>
    </>
  );
}

export default Logout;
