import { createSlice } from "@reduxjs/toolkit";
import { basePrice } from "../utils/helpers";

const initialState = {
  cartLength: 0,
  cartProducts: [],
  subTotal: "0.00",
  total: "0.00",
};

export const Cart = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    initialCartState: (state, action) => {
      const { cartProducts } = action.payload;

      const updatedCartProducts = cartProducts.map((product) => ({
        ...product,
        price: basePrice(product, 1),
        total: basePrice(product, Number(product?.quantity)),
      }));
      const cartLength = updatedCartProducts.reduce(
        (acc, product) => acc + Number(product.quantity),
        0
      );
      const subTotal = parseFloat(
        updatedCartProducts.reduce(
          (subTotal, product) => subTotal + Number(product.total),
          0
        )
      ).toFixed(2);
      const total = subTotal;
      return {
        ...state,
        cartLength,
        cartProducts: updatedCartProducts,
        subTotal,
        total,
      };
    },
    localStorageInitailCartState: (state, action) => {
      const { cartProducts } = action.payload;
      const cartLength = cartProducts.reduce(
        (acc, product) => acc + Number(product.quantity),
        0
      );
      const subTotal = parseFloat(
        cartProducts.reduce(
          (subTotal, product) => subTotal + Number(product.total),
          0
        )
      ).toFixed(2);

      const total = subTotal;
      return {
        ...state,
        cartLength,
        cartProducts,
        subTotal,
        total,
      };
    },
    addToCart: (state, action) => {
      const { cartProduct } = action.payload;

      // Retrieve existing products from localStorage, or initialize as an empty array if none exist
      let addedCartProducts = state.cartProducts;

      // Find the index of the product in the cartProducts, if it already exists
      const existingProductIndex = addedCartProducts.findIndex(
        (item) => item.product_variant_id === cartProduct.product_variant_id
      );

      if (existingProductIndex > -1) {
        // If the product exists, increase its quantity by the selected quantity
        addedCartProducts[existingProductIndex].quantity +=
          cartProduct.quantity;
        addedCartProducts[existingProductIndex].total = parseFloat(
          Number(addedCartProducts[existingProductIndex].quantity) *
            Number(cartProduct.price)
        ).toFixed(2);

        // Move the updated product to the beginning of the array
        const [updatedProduct] = addedCartProducts.splice(
          existingProductIndex,
          1
        );
        addedCartProducts.unshift(updatedProduct);
      } else {
        // If the product is new, add it to the beginning of the array
        addedCartProducts.unshift(cartProduct);
      }

      // Save the updated array back to localStorage
      localStorage.setItem("cartProducts", JSON.stringify(addedCartProducts));

      // Update the state properties directly without returning a new object
      state.cartProducts = addedCartProducts;
      state.cartLength = addedCartProducts.reduce(
        (acc, product) => acc + Number(product.quantity),
        0
      );
      state.subTotal = parseFloat(
        addedCartProducts.reduce(
          (subTotal, product) => subTotal + Number(product.total),
          0
        )
      ).toFixed(2);
      state.total = state.subTotal; // Assuming no additional discounts for now
    },
    deleteCartItem: (state, action) => {
      const { product_variant_id } = action.payload;
      const updatedCartProducts = state.cartProducts.filter(
        (product) => product.product_variant_id !== product_variant_id
      );

      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));

      state.cartProducts = updatedCartProducts;
      state.cartLength = updatedCartProducts.reduce(
        (acc, product) => acc + Number(product.quantity),
        0
      );
      state.subTotal = parseFloat(
        updatedCartProducts.reduce(
          (subTotal, product) => subTotal + Number(product.total),
          0
        )
      ).toFixed(2);
      state.total = state.subTotal; // Assuming no additional discounts for now
    },
    productQuantityChange: (state, action) => {
      const { product_variant_id, quantity } = action.payload;
      const updatedCartProducts = state.cartProducts.map((product) =>
        product.product_variant_id === product_variant_id
          ? {
              ...product,
              quantity,
              total: parseFloat(
                Number(product.price || 0) * Number(quantity)
              ).toFixed(2),
            }
          : product
      );

      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));

      state.cartProducts = updatedCartProducts;
      state.cartLength = updatedCartProducts.reduce(
        (acc, product) => acc + Number(product.quantity),
        0
      );
      state.subTotal = parseFloat(
        updatedCartProducts.reduce(
          (subTotal, product) => subTotal + Number(product.total),
          0
        )
      ).toFixed(2);
      state.total = state.subTotal; // Assuming no additional discounts for now
    },
    removeAllCartItems: (state, action) => {
      localStorage.removeItem("cartProducts");
      return {
        ...initialState,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addToCart,
  deleteCartItem,
  productQuantityChange,
  initialCartState,
  removeAllCartItems,
  localStorageInitailCartState,
} = Cart.actions;

export default Cart.reducer;
