import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";

const SendEmail = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [formData, setFormData] = useState({});
  const [sent, setSent] = useState(1);
  const [seconds, setSeconds] = useState(0); // Initial time set to 0
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [sendBusy, setSendBusy] = useState(false);
  const [success, setSuccess] = useState(true);
  const [busy, setBusy] = useState(false);
  const [client_email, setClient_email] = useState("");
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleReset = async (e) => {
    e.preventDefault();
    let formValid = true;
    const newErrors = {};

    // Check for password length
    if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
      formValid = false;
    }

    // Check for confirm password length
    if (formData.confirmPassword.length < 8) {
      newErrors.confirmPassword =
        "Confirm Password must be at least 8 characters long.";
      formValid = false;
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password does not match !";
      formValid = false;
    }

    setErrors(newErrors);
    if (!formValid) return;

    // If form is valid, proceed with form submission logic
    setBusy(true);
    try {
      const response = await axios.post("/auth/reset_password", {
        ...formData,
        client_email,
      });
      const result = response?.data;
      alert(result?.message);
      navigate("/auth/login");
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setBusy(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    const OTP = otp.join("");
    setError("");
    setSuccess("");
    setIsError(false);

    // Verify OTP length
    if (OTP.length !== 6) {
      setError("OTP must be 6 digits.");
      setIsError(true);
      return;
    }
    setBusy(true);
    try {
      const response = await axios.post("/auth/verify_otp", {
        client_email,
        OTP,
      });
      const result = response?.data;
      setOtp(new Array(6).fill(""));
      setSent(3);
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setBusy(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendBusy(true);
    setSuccess("");
    setError("");
    try {
      const response = await axios.post("/auth/forgot_password", {
        client_email,
      });
      const result = response?.data;
      setSent(2);
      setSeconds(30); // Start countdown from 30 seconds

      const countdown = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(countdown); // Stop countdown when it reaches 0
            return 0; // Set to 0 when countdown finishes
          }
          return prevSeconds - 1;
        });
      }, 1000);
      setSuccess(result?.message);
      setOtp(new Array(6).fill(""));
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setSendBusy(false);
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus the next input if a value is entered
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace") {
      // Clear the current input field if backspace is pressed
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Focus the previous input if available
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    }
  };

  useEffect(() => {
    const OTP = otp.join("");
    if (OTP.length === 6) {
      setIsError(false); // Remove error if OTP is valid
      setError("");
    }
  }, [otp]); // Runs every time `otp` changes

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12 contents">
          <div className="row justify-content-center">
            <div className="col-md-8 col-xl-6">
              {sent === 1 ? (
                <>
                  <div className="mb-4 mt-4">
                    <h3>Forgot Password</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {error}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError("")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}
                    <div className="form-group first">
                      <label htmlFor="client_email">Email *</label>
                      <input
                        type="email"
                        id="client_email"
                        placeholder="Enter your registered email address"
                        className="form-control"
                        required
                        name="client_email"
                        pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                        title="Please enter a valid email format, no double dots, and no trailing dot."
                        value={client_email || ""}
                        onChange={(e) => setClient_email(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mb-5 align-items-center"></div>
                    <input
                      type="submit"
                      value="Request OTP"
                      className="btn text-white btn-block btn-success"
                      disabled={sendBusy}
                    />
                    <span className="login_forgot">
                      <Link
                        to="/auth/login"
                        className="d-block text-left my-4 "
                      >
                        {" "}
                        <i className="fa fa-arrow-left"></i>&nbsp;&nbsp;Back to
                        Login
                      </Link>
                    </span>
                  </form>
                </>
              ) : sent === 2 ? (
                <>
                  <div className="mb-4 mt-4">
                    <h5>
                      Please enter the OTP sent to {client_email}.&nbsp;{" "}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => setSent(1)}
                      >
                        Change
                      </span>
                    </h5>
                  </div>
                  <form onSubmit={handleVerify}>
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {error}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError("")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}
                    {success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        {success}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setSuccess("")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}

                    <div className="d-flex mb-5 align-items-center justify-content-center">
                      {otp.map((_, index) => (
                        <input
                          key={index}
                          type="text"
                          className={`form-control mx-1 text-center ${
                            isError ? "border-danger" : ""
                          }`}
                          maxLength="1"
                          value={otp[index]}
                          onChange={(e) => handleChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onFocus={(e) => e.target.select()}
                          style={{
                            width: "3rem",
                            height: "3rem",
                            fontSize: "1.5rem",
                          }}
                        />
                      ))}
                    </div>
                    <input
                      type="submit"
                      value="Verify"
                      className="btn text-white btn-block btn-success"
                      disabled={busy}
                    />
                    <div className="login_forgot justify-content-end">
                      <span className="d-block text-left my-4 ">
                        Not received your OTP?{" "}
                        {seconds > 0 ? (
                          <strong>
                            00:{seconds.toString().padStart(2, "0")}
                          </strong>
                        ) : (
                          <span
                            className="text-primary hover-cursor-pointer"
                            onClick={!sendBusy ? handleSubmit : undefined}
                          >
                            Resend OTP
                          </span>
                        )}
                      </span>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="mb-4 mt-4">
                    <h3>Reset Password</h3>
                  </div>
                  <form onSubmit={handleReset}>
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {error}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError("")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )}
                    <div className="form-group first">
                      <label htmlFor="password">Password *</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        required
                        minLength={8}
                        value={formData?.password || ""}
                        onChange={(e) =>
                          setFormData((pre) => ({
                            ...pre,
                            password: e.target.value,
                          }))
                        }
                      />
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </div>
                    <div className="form-group last mb-4">
                      <label htmlFor="confirmPassword">
                        Confirm Password *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        minLength={8}
                        value={formData?.confirmPassword || ""}
                        onChange={(e) =>
                          setFormData((pre) => ({
                            ...pre,
                            confirmPassword: e.target.value,
                          }))
                        }
                      />
                      {errors.confirmPassword && (
                        <div className="text-danger">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>

                    <input
                      type="submit"
                      value="Submit"
                      className="btn text-white btn-block btn-success my-4"
                      disabled={busy}
                    />
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendEmail;
