import axios from "axios";

const useAxios = () => {
  const Axios = axios.create({
    baseURL: "https://api.edutools.in",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // const { status } = error.response;
      // if (status === 401) {
      //   localStorage.clear();
      //   window.location.href = "/";
      // }
      return Promise.reject(error);
    }
  );
  return Axios;
};

export default useAxios;
