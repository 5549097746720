import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  setting: {},
};
const Setting = createSlice({
  name: "Setting",
  initialState,
  reducers: {
    initialSettingState: (state, action) => {
      const { setting } = action.payload;
      return {
        ...state,
        setting,
      };
    },
  },
});

export const { initialSettingState } = Setting.actions;

export default Setting.reducer;
