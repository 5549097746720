import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../../img/breadcrumb.jpg";
import Product1 from "../../img/product/product-1.jpg";
import Product2 from "../../img/product/product-2.jpg";
import Product3 from "../../img/product/product-3.jpg";
import Product7 from "../../img/product/product-7.jpg";
import NoImage from "../../img/product_no_image.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, initialCartState } from "../../reduxStore/Cart";
import { initialWishlistState } from "../../reduxStore/Wishlist";
import { basePrice, capitalizeWords, encryptId } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginModal from "../auth/login/LoginModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import { resetMeta, setMeta } from "../../reduxStore/Meta";
import Loader from "../../components/Loader";
import CryptoJS from "crypto-js";

const ShopDetails = () => {
  const axios = useAxios();
  const slider = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.Auth);
  const { product_id } = useParams();
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [images, setImages] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(NoImage);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  const { cartProducts } = useSelector((state) => state.Cart);
  const [loading, setLoading] = useState(true);
  const shareRef = useRef(null);
  const [decryptedId, setDecryptedId] = useState(null);

  const secretKey = "12345";

  useEffect(() => {
    if (product_id) {
      const bytes = CryptoJS.AES.decrypt(product_id, secretKey);
      const originalId = bytes.toString(CryptoJS.enc.Utf8);

      if (originalId) {
        setDecryptedId(originalId);
      } else {
        console.error("Decryption failed!");
      }
    }
  }, [product_id]);

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  // images[0] ? images[0]?.imagePath

  useEffect(() => {
    if (images?.[0]?.images?.length > 0) {
      setSelectedImage(images[0]?.images[0]?.imagePath || NoImage); // Correctly access the first image in the first item of images array
    }
  }, [images]); // Re-run the effect when images prop changes

  const getWishlists = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/wishlist/get_wish_list");
      const result = response?.data;
      dispatch(initialWishlistState({ wishlist: result?.data || [] }));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const addToWishList = async () => {
    if (!isLoggedIn) setShowLoginModal(true);
    else {
      setLoading(true);
      try {
        const response = await axios.post("/wishlist/add_to_wishlist", {
          product_id: decryptedId,
        });
        const result = response?.data;
        toast("Wishlist Update Sucessfully!");
        getProducts();
        getWishlists();
      } catch (error) {
        setLoading(false);
      } finally {
      }
    }
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  const postAddToCart = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/cart/add_to_cart", {
        product_variant_id: product.id,
        quantity,
      });
      const result = response?.data;
      toast("Item Added to Cart");
      getCartItems();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = () => {
    const findProduct = cartProducts.find(
      (item) => item.product_variant_id === product.id
    );
    if (findProduct) {
      if (Number(findProduct.quantity) >= 20) {
        toast.error(
          "You already have 20 quantity of this product in your cart."
        );
        return;
      }
    }
    setLoading(true);
    if (isLoggedIn) {
      postAddToCart();
    } else {
      const cartProduct = {
        ...product,
        product_name: product?.product_name,
        product_variant_id: product.id,
        quantity, // The current quantity to add
        price: parseFloat(Number(basePrice(product, 1))).toFixed(2),
        total: parseFloat(Number(basePrice(product, 1))).toFixed(2),
      };
      dispatch(addToCart({ cartProduct }));
      toast("Item Added to Cart");
      setLoading(false);
    }
  };

  const buyNowHandler = () => {
    handleAddToCart();
    navigate("/shopping_cart");
  };

  const getProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/products/product_details/${decryptedId}${
          isLoggedIn ? `?client_id=${user?.id}` : ""
        }`
      );
      const result = response?.data;
      setProduct(result?.data);
      setSelectedImage(result.data.base_image_url);
    } catch (error) {
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const getImages = async () => {
    try {
      const response = await axios.get(
        `/products/product_images/${decryptedId}`
      );
      const result = response?.data;
      setImages(result?.data);
    } catch (error) {}
  };

  const getRelatedProducts = async () => {
    try {
      const response = await axios.get(
        `/products/get_related_products/${decryptedId}`
      );
      const result = response?.data;
      setRelatedProducts(result?.data || []);
    } catch (error) {}
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
  };

  useEffect(() => {
    if (decryptedId) {
      getProducts();
      getImages();
    } else {
      setLoading(false);
    }
    return () => {
      setImages([]);
    };
  }, [decryptedId, isLoggedIn]);

  useEffect(() => {
    if (decryptedId) getRelatedProducts();
  }, [decryptedId]);

  useEffect(() => {
    // Set meta for the page based on category_name
    dispatch(
      setMeta({
        title: capitalizeWords(product?.product_name || ""),
        description: product?.product_description,
      })
    );

    // Clean up on unmount
    return () => {
      dispatch(resetMeta());
    };
  }, [dispatch, product]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShowShareOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: images.length > 5, // Infinite only if more than 5 images
    speed: 1000,
    slidesToShow: 5, // Show as many images as available, up to 5
    slidesToScroll: Math.min(5, images.length), // Scroll as many images as available, up to 5
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: images.length > 4,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(4, images.length),
          slidesToScroll: Math.min(4, images.length),
          initialSlide: 0, // Adjust for smaller screens
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(3, images.length),
          slidesToScroll: Math.min(3, images.length),
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (!Object.keys(product)?.length && !loading && !decryptedId)
      navigate("/");
  }, [Object.keys(product)?.length, loading, decryptedId]);

  return (
    <>
      {loading && <Loader />}
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>{capitalizeWords(product?.product_name || "")}</h2>
                <div className="breadcrumb__option">
                  <Link to={"/"}>Home</Link>
                  <Link to={"/"}>
                    {product?.parent_main_category === 1
                      ? "Book"
                      : "Stationery"}
                  </Link>
                  <span>{capitalizeWords(product?.product_name || "")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-details spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="product__details__pic">
                <div
                  className="product__details__pic__item"
                  style={{ position: "relative" }}
                  ref={shareRef}
                >
                  <img
                    className="product__details__pic__item--large"
                    src={selectedImage || NoImage}
                    alt="Main Product"
                  />

                  {/* Share Button */}
                  <button
                    className="share-button"
                    onClick={toggleShareOptions}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",
                      border: "none",
                      borderRadius: "50%",
                      width: "32px",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-share-alt"></i>
                  </button>

                  {/* Share Options */}
                  {showShareOptions && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50px",
                        right: "10px",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        padding: "10px",
                        zIndex: 1000,
                        width: "235px",
                      }}
                    >
                      <FacebookShareButton
                        url={currentUrl}
                        style={{ margin: "0px 5px" }}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={currentUrl}
                        style={{ margin: "0px 5px" }}
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={currentUrl}
                        style={{ margin: "0px 5px" }}
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton
                        url={currentUrl}
                        style={{ margin: "0px 5px" }}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <EmailShareButton
                        url={currentUrl}
                        style={{ margin: "0px 5px" }}
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>

                      <button
                        onClick={handleCopyLink}
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          backgroundColor: "transparent",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          textAlign: "center",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        {copied ? "Link Copied!" : "Copy Link"}
                      </button>
                    </div>
                  )}
                </div>
                {/* <p>asd</p> */}
                {images?.length ? (
                  <div className="product__details__pic__slider ">
                    <Slider {...sliderSettings} ref={slider}>
                      {images.map((image, index) => (
                        <section key={index}>
                          <div
                            onClick={() =>
                              handleImageClick(image.imagePath || NoImage)
                            }
                            className="prduct__details__pic_slider_img set-bg no__cover"
                            style={{
                              backgroundImage: `url(${
                                image.imagePath || NoImage
                              })`,
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              border:
                                selectedImage === image?.imagePath
                                  ? "2px solid #000"
                                  : "none",
                              cursor: "pointer",
                              // width:'100px'
                            }}
                          ></div>
                        </section>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="product__details__text">
                <h5>{capitalizeWords(product?.product_full_name || "")}</h5>
                <div className="product__details__price">
                  <div className="price-wrapper">
                    ₹&nbsp;{basePrice(product, 1)}
                    {Number(product?.product_discount) > 0 ? (
                      <>
                        <span className="original-price">
                          ₹&nbsp;{product?.product_price}
                        </span>
                        <span className="text-success">
                          {product?.product_discount}
                          {product?.product_discount_type === 0 ? (
                            <span>%</span>
                          ) : (
                            <span>₹</span>
                          )}{" "}
                          off
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* {product?.product_short_description ? (
                  <p>{product?.product_short_description || ""}</p>
                ) : (
                  ""
                )} */}
                {/* {product.closing_stock > 0 ? (
                  <span
                    className="primary-btn hover-cursor-pointer"
                    onClick={handleAddToCart}
                  >
                    ADD TO CART
                  </span>
                ) : (
                  <span
                    className="out-of-stock"
                    style={{
                      color: "red",
                      fontSize: "20px",
                      padding: "10px",
                      marginRight: "10px",
                    }}
                  >
                    Out of Stock
                  </span>
                )} */}

                <span
                  className="primary-btn hover-cursor-pointer"
                  onClick={handleAddToCart}
                >
                  ADD TO CART
                </span>
                {/* <span
                  className="primary-btn hover-cursor-pointer"
                  onClick={buyNowHandler}
                >
                  Buy Now
                </span> */}
                <span
                  className="heart-icon hover-cursor-pointer"
                  onClick={addToWishList}
                >
                  <i
                    className={`fa fa-heart ${
                      product?.is_favorite === 1 ? "active" : ""
                    }`}
                  />
                </span>
                <ul>
                  {product?.product_short_description && (
                    <li>
                      <h5 className="mb-3">
                        {capitalizeWords(
                          product?.product_short_description || ""
                        )}
                      </h5>
                    </li>
                  )}

                  {/* {product?.stock && ( */}
                  {/* <li>
                    <b>Stock: </b>
                    <span>
                      {product.closing_stock > 0 ? "In Stock" : "Out of Stock"}
                    </span>
                  </li> */}
                  {/* )} */}

                  {product?.product_name && (
                    <li>
                      <b>Product Name: </b>
                      <span>
                        {capitalizeWords(product?.product_name || "")}
                      </span>
                    </li>
                  )}

                  {product?.product_article_number && (
                    <li>
                      <b>Article Number: </b>
                      <span>{product?.product_article_number}</span>
                    </li>
                  )}

                  {/* {product?.product_categories && (
    <li>
      <b>Categories: </b>
      <span>{product?.product_categories}</span>
    </li>
  )} */}

                  {product?.company_name ? (
                    <li>
                      <b>Company: </b>
                      <span>
                        {capitalizeWords(product?.company_name || "")}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                  {product?.product_description && (
                    <li>
                      <b>Description: </b>
                      <span>
                        {capitalizeWords(product?.product_description || "")}
                      </span>
                    </li>
                  )}

                  {/* {product?.product_price && (
    <li>
      <b>Price: </b>
      <span>{product?.product_price ? `₹${product.product_price}` : "N/A"}</span>
    </li>
  )} */}

                  {/* {product?.product_discount_type && (
    <li>
      <b>Discount Type: </b>
      <span>{product?.product_discount_type}</span>
    </li>
  )} */}

                  {/* {product?.product_discount && (
    <li>
      <b>Discount: </b>
      <span>{product?.product_discount}%</span>
    </li>
  )} */}

                  {/* {product?.product_tax && (
    <li>
      <b>Tax: </b>
      <span>{product?.product_tax}%</span>
    </li>
  )} */}

                  {/* {product?.product_country_origin && (
                    <li>
                      <b>Country of Origin: </b>
                      <span>{product?.product_country_origin}</span>
                    </li>
                  )} */}

                  {product?.product_importer && (
                    <li>
                      <b>Importer: </b>
                      <span>{product?.product_importer}</span>
                    </li>
                  )}

                  {product?.product_packer && (
                    <li>
                      <b>Packer: </b>
                      <span>{product?.product_packer}</span>
                    </li>
                  )}

                  {product?.product_unit_of_measurement && (
                    <li>
                      <b>Measurement: </b>
                      <span>{product?.product_unit_of_measurement}</span>
                    </li>
                  )}

                  {product?.product_class_grade && (
                    <li>
                      <b>Class/Grade: </b>
                      <span>
                        {capitalizeWords(product?.product_class_grade || "")}
                      </span>
                    </li>
                  )}

                  {product?.product_edition && (
                    <li>
                      <b>Edition: </b>
                      <span>{product?.product_edition}</span>
                    </li>
                  )}

                  {product?.product_release_date && (
                    <li>
                      <b>Release Date: </b>
                      <span>{product?.product_release_date}</span>
                    </li>
                  )}

                  {product?.variation_options && (
                    <li>
                      <b>Variation Options: </b>
                      <span>{product?.variation_options}</span>
                    </li>
                  )}

                  {product?.product_more_details && (
                    <li>
                      <b>More Details: </b>
                      <span>
                        {capitalizeWords(product?.product_more_details || "")}
                      </span>
                    </li>
                  )}

                  {product?.product_color && (
                    <li>
                      <b>Color: </b>
                      <span>
                        {capitalizeWords(product?.product_color || "")}
                      </span>
                    </li>
                  )}

                  {product?.product_size && (
                    <li>
                      <b>Size: </b>
                      <span>{product?.product_size}</span>
                    </li>
                  )}

                  {product?.product_back_cover && (
                    <li>
                      <b>Back Cover: </b>
                      <span>
                        {capitalizeWords(product?.product_back_cover || "")}
                      </span>
                    </li>
                  )}

                  {product?.no_of_pages && (
                    <li>
                      <b>No of Pages: </b>
                      <span>{product?.no_of_pages}</span>
                    </li>
                  )}

                  {product?.ruling && (
                    <li>
                      <b>Ruling: </b>
                      <span>{capitalizeWords(product?.ruling || "")}</span>
                    </li>
                  )}

                  {product?.ink_color && (
                    <li>
                      <b>Ink Color: </b>
                      <span>{capitalizeWords(product?.ink_color || "")}</span>
                    </li>
                  )}

                  {product?.board && (
                    <li>
                      <b>Board: </b>
                      <span>{capitalizeWords(product?.board || "")}</span>
                    </li>
                  )}

                  {product?.subject && (
                    <li>
                      <b>Subject: </b>
                      <span>{capitalizeWords(product?.subject || "")}</span>
                    </li>
                  )}

                  {product?.writer && (
                    <li>
                      <b>Writer: </b>
                      <span>{capitalizeWords(product?.writer || "")}</span>
                    </li>
                  )}

                  {product?.product_language && (
                    <li>
                      <b>Language: </b>
                      <span>
                        {capitalizeWords(product?.product_language || "")}
                      </span>
                    </li>
                  )}

                  {/* {product?.product_full_name && (
                    <li>
                      <b>Full Product Name: </b>
                      <span>{product?.product_full_name}</span>
                    </li>
                  )} */}

                  {/* <li>
    <b>Share on</b>
    <div className="share">
      <a href="#">
        <i className="fa fa-facebook"></i>
      </a>
      <a href="#">
        <i className="fa fa-twitter"></i>
      </a>
      <a href="#">
        <i className="fa fa-instagram"></i>
      </a>
      <a href="#">
        <i className="fa fa-pinterest"></i>
      </a>
    </div>
  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="product__details__tab">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <span className="">Description</span>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="product__details__tab__desc">
                      {/* <h6>Products Information</h6> */}
                      <p>
                        {capitalizeWords(product?.product_description || "")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="related-product">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title related__product__title">
                <h2>Related Product</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {relatedProducts?.map((product, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={index}>
                <div
                  className="product__item hover-cursor-pointer"
                  onClick={() => {
                    const encryptedId = encryptId(product?.id, secretKey);
                    navigate(
                      `/shop-details/${product?.product_full_name?.replace(
                        /[ /]+/g,
                        "-"
                      )}/${encodeURIComponent(encryptedId)}`
                    );
                  }}
                >
                  <div
                    className="product__item__pic set-bg no__cover"
                    style={{
                      backgroundImage: `url(${
                        product.base_image_url
                          ? product.base_image_url
                          : NoImage
                      })`,
                    }}
                  >
                    {/* <ul className="product__item__pic__hover">
                      <li>
                        <a href="#">
                          <i className="fa fa-heart"></i>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <i className="fa fa-shopping-cart"></i>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                  <div className="product__item__text">
                    <h6>
                      <a href="#">
                        {capitalizeWords(product?.product_name || "")}
                      </a>
                    </h6>
                    <div className="price-wrapper">
                      <h5>₹&nbsp;{basePrice(product, 1)}</h5>
                      {Number(product?.product_discount) > 0 ? (
                        <>
                          <span className="original-price">
                            ₹&nbsp;{product?.product_price}
                          </span>
                          <span className="text-success">
                            {product?.product_discount}
                            {product?.product_discount_type === 0 ? (
                              <span>%</span>
                            ) : (
                              <span>₹</span>
                            )}{" "}
                            off
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <LoginModal
              show={showLoginModal}
              onHide={() => setShowLoginModal(false)}
            />
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopDetails;
