import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: {},
};

export const Auth = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        user,
      };
    },
    removeLoggedInInfo: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, removeLoggedInInfo } = Auth.actions;

export default Auth.reducer;
