import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../img/breadcrumb.jpg";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../utils/axiosInstance";
import Loader from "../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initialCartState } from "../../reduxStore/Cart";

const Checkout = () => {
  const axios = useAxios();
  const { cartProducts, subTotal, total } = useSelector((state) => state.Cart);
  const { user } = useSelector((state) => state.Auth);
  const [allActiveStates, setAllActiveStates] = useState([]);
  const [formData, setFormData] = useState({});
  const [addformData, setaddFormData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showForm, setShowForm] = useState(false); // false to start with the form collapsed
  const [address, setAddress] = useState([]);
  const [shippingAddressId, setShippingAddressId] = useState(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null); // Track selected address ID
  const [showAll, setShowAll] = useState(false);

  const displayedAddresses = showAll ? address : address.slice(0, 3);

  const [newAddress, setNewAddress] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    phone_number: "",
  });

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
    // setNewAddress(address);
  };
  const handleEditAddress = (address) => {
    // setSelectedAddress(address);
    setNewAddress(address);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleFormDataInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const getAllAddress = async () => {
    setLoading(true); // Start loading before making the API call
    try {
      const response = await axios.get("/address/get_shipping_address");
      const result = response?.data;
      if (result?.success) {
        setAddress(result?.data || []); // Set the state with the fetched data
      } else {
        console.error("Failed to fetch shipping addresses:", result?.message);
      }
    } catch (error) {
      console.error("Error fetching shipping addresses:", error);
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  };
  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
    const result = allActiveStates.find((state) => state.tin === value);
    if (result) {
      setFormData((pre) => ({ ...pre, client_state: result.state_name }));
    } else {
      setFormData((pre) => ({ ...pre, client_state: "" }));
    }
  };
  const handleStateChange1 = (e) => {
    const { name, value } = e.target;
    setNewAddress((pre) => ({ ...pre, [name]: value }));
    const result = allActiveStates.find((state) => state.tin === value);
    if (result) {
      setNewAddress((pre) => ({ ...pre, state_name: result.state_name }));
    } else {
      setNewAddress((pre) => ({ ...pre, state_name: "" }));
    }
  };

  const handleAddressAdd = async () => {
    const addressData = {
      shipping_name: newAddress.name,
      shipping_phone: newAddress.phone_number,
      shipping_city: newAddress.city,
      shipping_address: newAddress.address,
      shipping_zip_code: newAddress.zipcode,
      shipping_state: newAddress.state_name,
      shipping_state_code: newAddress.client_state_code,
      shipping_country: "IN",
    };

    try {
      const response = await axios.post(
        "/address/add_shipping_address",
        addressData
      );
      getAllAddress();
      setShowForm(false);
      setNewAddress({
        name: "",
        phone_number: "",
        city: "",
        address: "",
        zipcode: "",
        state_name: "",
        client_state_code: "",
      });

      // Optional: Show a success message if needed
      toast("Address added successfully");
    } catch (verificationError) {
      console.error("Payment verification failed", verificationError);
      toast("Payment verified failed");
    }
  };

  const handleDeleteShipping = async (id) => {
    try {
      const response = await axios.delete(
        `/address/delete_shipping_address/${id}`
      );
      if (response?.data?.success) {
        toast("Address Deleted successfully!");
        getAllAddress(); // Refresh addresses after editing
      } else {
        toast("Failed to Delete address.");
      }
    } catch (error) {
      console.error("Error Deleting address:", error);
      toast("Error Deleting address.");
    }
  };

  const confirmDeleteShipping = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this shipping address?"
    );
    if (isConfirmed) {
      handleDeleteShipping(id);
    } else {
      toast("Address deletion canceled.");
    }
  };

  const handleAddressEdit = async (addressId) => {
    // e.preventDefault();

    const addressData = {
      shipping_name: newAddress.name,
      shipping_phone: newAddress.phone_number,
      shipping_city: newAddress.city,
      shipping_address: newAddress.address,
      shipping_zip_code: newAddress.zipcode,
      shipping_state: newAddress.state_name,
      shipping_state_code: newAddress.client_state_code,
      shipping_country: "IN",
    };
    try {
      const response = await axios.patch(
        `/address/update_shipping_address/${addressId}`,
        addressData
      );
      if (response?.data?.success) {
        toast("Address updated successfully!");
        getAllAddress(); // Refresh addresses after editing
        setShowForm(false); // Hide the form after updating

        setSelectedAddressId(addressId); // Use addressId directly here
      } else {
        toast("Failed to update address.");
      }
    } catch (error) {
      console.error("Error updating address:", error);
      toast("Error updating address.");
    }
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const placed_order = cartProducts?.map((product) => ({
      id: product.id,
      quantity: product.quantity,
    }));

    const userData = formData;

    if (!shippingAddressId && !isChecked) {
      toast("Please Select Shipping Address.");
      setButtonDisabled(false);
      return; // Make sure to stop further execution if the address is not selected
    }
    try {
      const response = await axios.post("/order/create_order", {
        placed_order,
        userData,
        shippingAddressId: isChecked ? null : shippingAddressId,
        billing_as_shipping: isChecked,
      });
      const { currency, id, amount } = response?.data.order;
      setButtonDisabled(false);
      const options = {
        key: "rzp_test_6MTLjFzCfZKHOk",
        amount: amount,
        currency: currency,
        name: "Edutools",
        description: "Test Transaction",
        // image: "/your_logo.png",
        order_id: id,
        handler: async function (response) {
          // Handle successful payment
          setLoading(true);
          try {
            const verification = await axios.post(
              "/order/payment_varification",
              {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              }
            );
            setLoading(false);
            toast("Payment verified successfully");
            getCartItems();
            navigate("/profile/orders");
            // window.location.reload();
          } catch (verificationError) {
            console.error("Payment verification failed", verificationError);
            toast("Payment verified failed");
            setLoading(false);
            setButtonDisabled(false);
          }
        },
        prefill: {
          name: formData.client_name,
          email: formData.client_email,
          contact: formData.client_phone,
        },
        notes: {
          address: formData.client_address,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error placing order:", error);
      setError("Failed to place order. Please try again.");
      setButtonDisabled(false);
    }
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {}
  };

  useMemo(() => {
    setFormData({
      client_name: user?.client_name,
      client_country: user?.client_country,
      client_address: user?.client_address,
      client_city: user?.client_city,
      client_state: user?.client_state,
      client_state_code: user.client_state_code,
      client_zipcode: user?.client_zipcode,
      client_phone: user?.client_phone,
      client_email: user?.client_email,
    });
  }, [user]);

  const getAllActiveStates = async () => {
    try {
      const response = await axios.get("/general/active_states");
      const result = response?.data;
      setAllActiveStates(result?.data);
    } catch (error) {}
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if we're adding or updating an address
    if (selectedAddress) {
      handleAddressEdit(selectedAddress.id); // Update address
    } else {
      handleAddressAdd(); // Add new address
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleShippingCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    getAllActiveStates();
    getAllAddress();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Checkout</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Checkout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="checkout spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError("")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="checkout__form">
            <div className="">
              {!isChecked && (
                <div className="">
                  <h4>Shipping Address</h4>

                  {/* Shipping Address Selection */}

                  <div>
                    <div>
                      {displayedAddresses.length > 0 ? (
                        displayedAddresses.map((addressItem, index) => (
                          <div
                            key={index}
                            className="address-item"
                            style={{
                              display: "flex",
                              fontSize: "18px",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <input
                              type="radio"
                              checked={selectedAddressId === addressItem.id}
                              onChange={() => {
                                handleSelectAddress(addressItem);
                                setSelectedAddressId(addressItem.id);
                                setShippingAddressId(addressItem.id);
                                setShowForm(false);
                              }}
                              className="select-address-btn"
                              style={{ marginRight: "10px" }}
                            />
                            <div
                              className={`address-details ${
                                selectedAddressId === addressItem.id
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              {addressItem.shipping_name},{" "}
                              {addressItem.shipping_phone},{" "}
                              {addressItem.shipping_address},{" "}
                              {addressItem.shipping_city},{" "}
                              {addressItem.shipping_country},{" "}
                              {addressItem.shipping_state}{" "}
                              {addressItem.shipping_zip_code}
                            </div>
                            {selectedAddressId === addressItem.id && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowForm(true);
                                  setNewAddress({
                                    name: addressItem.shipping_name,
                                    address: addressItem.shipping_address,
                                    city: addressItem.shipping_city,
                                    state: addressItem.shipping_state,
                                    zipcode: addressItem.shipping_zip_code,
                                    country: addressItem.shipping_country,
                                    phone_number: addressItem.shipping_phone,
                                    client_state_code:
                                      addressItem.shipping_state_code,
                                  });
                                }}
                                className="btn edit-address-btn"
                                style={{ color: "green", marginLeft: "20px" }}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;Edit
                              </button>
                            )}
                            {selectedAddressId === addressItem.id && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmDeleteShipping(addressItem.id);
                                  // setNewAddress({
                                  //   name: addressItem.shipping_name,
                                  //   address: addressItem.shipping_address,
                                  //   city: addressItem.shipping_city,
                                  //   state: addressItem.shipping_state,
                                  //   zipcode: addressItem.shipping_zip_code,
                                  //   country: addressItem.shipping_country,
                                  //   phone_number: addressItem.shipping_phone,
                                  //   client_state_code:
                                  //     addressItem.shipping_state_code,
                                  // });
                                }}
                                className="btn edit-address-btn"
                                style={{ color: "Red", marginLeft: "20px" }}
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                &nbsp;Delete
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <p>
                          No saved addresses found. Add a new address below.
                        </p>
                      )}

                      {/* Show More button */}
                      {address.length > 3 && !showAll && (
                        <button
                          onClick={() => setShowAll(true)}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                        >
                          <i
                            class="fa fa-angle-double-down"
                            aria-hidden="true"
                          ></i>
                          &nbsp;Show More
                        </button>
                      )}
                      {showAll && address.length > 3 && (
                        <button
                          onClick={() => setShowAll(false)}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontWeight: "600",
                          }}
                        >
                          <i
                            class="fa fa-angle-double-up"
                            aria-hidden="true"
                          ></i>
                          &nbsp;Show Less
                        </button>
                      )}
                    </div>

                    {/* Toggle form for adding a new address or editing an existing one */}
                    <div className="address-form-toggle">
                      {/* Add New Address button */}
                      <button
                        onClick={(e) => {
                          e.preventDefault(); // Prevent form submission
                          setShowForm(true); // Show the form for adding a new address
                          setSelectedAddressId(null); // Clear any selected address
                          setNewAddress({
                            name: "",
                            phone_number: "",
                            city: "",
                            address: "",
                            zipcode: "",
                            state_name: "",
                            client_state_code: "",
                          });
                        }}
                        style={{
                          background: "#7fad38",
                          border: "none",
                          borderRadius: "5px",
                          color: "#fff",
                          padding: "10px",
                          marginTop: "20px",
                        }}
                      >
                        Add New Address
                      </button>
                    </div>

                    {showForm && (
                      <div className="address-form">
                        <form onSubmit={handleFormSubmit}>
                          <div className="input-group">
                            <div className="input-field">
                              <label>Name</label>
                              <input
                                type="text"
                                name="name"
                                minLength={3}
                                maxLength={224}
                                required
                                value={newAddress.name}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="input-field">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                name="phone_number"
                                pattern="^[6-9]\d{9}$"
                                title="Please enter a valid 10-digit phone number"
                                maxLength={10}
                                value={newAddress.phone_number}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="input-group">
                            <div className="input-field">
                              <label>Country</label>
                              <input
                                type="text"
                                name="client_country"
                                value="IN" // Country is always IN
                                readOnly
                                required
                              />
                            </div>

                            <div className="input-field">
                              <label>State</label>
                              <select
                                className="custom-select"
                                name="client_state_code"
                                required
                                value={newAddress.client_state_code || ""}
                                onChange={handleStateChange1}
                              >
                                <option value="">State</option>
                                {allActiveStates?.map((state) => (
                                  <option key={state.tin} value={state.tin}>
                                    {state.state_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="input-group">
                            <div className="input-field">
                              <label>City</label>
                              <input
                                type="text"
                                name="city"
                                value={newAddress.city}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="input-field">
                              <label>Zipcode</label>
                              <input
                                type="text"
                                name="zipcode"
                                pattern="^[1-9][0-9]{5}$"
                                title="Please enter a valid 6-digit zip code starting with a non-zero digit."
                                maxLength={6}
                                value={newAddress.zipcode}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="input-group">
                            <div className="input-field">
                              <label>Address</label>
                              <input
                                type="text"
                                name="address"
                                value={newAddress.address}
                                onChange={handleInputChange}
                                required
                              />
                            </div>

                            <div className="input-field">
                              <input
                                style={{
                                  background: "#7fad38",
                                  border: "none",
                                  borderRadius: "5px",
                                  color: "#fff",
                                  marginTop: "30px",
                                }}
                                type="submit"
                                value={
                                  selectedAddress
                                    ? "Update Address"
                                    : "Add Address"
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div style={{ marginTop: "20px" }}>
                <input
                  type="checkbox"
                  className="select-address-btn"
                  style={{ marginRight: "10px" }}
                  checked={isChecked} // Bind the checked state to the checkbox
                  onChange={handleShippingCheckboxChange} // Update state on change
                />
                {/* You can display whether it's checked */}
                <label>Shipping address same as billing address</label>
              </div>
            </div>

            <h4 style={{ marginTop: "20px" }}>Billing Details</h4>
            <form onSubmit={handlePlaceOrder}>
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  <div className="checkout__input">
                    <p>
                      Name<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_name"
                      minLength={3}
                      maxLength={224}
                      required
                      readOnly
                      value={formData?.client_name}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Country<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_country"
                      required
                      readOnly
                      value={formData?.client_country}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Address<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_address"
                      placeholder="Street Address"
                      className="checkout__input__add"
                      required
                      value={formData?.client_address}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Town/City<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_city"
                      required
                      value={formData?.client_city}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Country/State<span>*</span>
                    </p>
                    <select
                      className="custom-select"
                      name="client_state_code"
                      required
                      disabled
                      value={formData?.client_state_code || ""}
                      onChange={handleStateChange}
                    >
                      <option value="" disabled>
                        State
                      </option>
                      {allActiveStates?.map((state) => (
                        <option key={state.tin} value={state.tin}>
                          {state.state_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="checkout__input">
                    <p>
                      Postcode / ZIP<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_zipcode"
                      pattern="^[1-9][0-9]{5}$"
                      title="Please enter a valid 6-digit zip code starting with a non-zero digit."
                      maxLength={6}
                      required
                      value={formData?.client_zipcode}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Order notes<span></span>
                    </p>
                    <input
                      type="text"
                      name="order_notes"
                      placeholder="Notes about your order, e.g. special notes for delivery."
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Phone<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_phone"
                      pattern="^[6-9]\d{9}$"
                      title="Please enter a valid 10-digit phone number"
                      maxLength={10}
                      required
                      readOnly
                      value={formData?.client_phone}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                  <div className="checkout__input">
                    <p>
                      Email<span>*</span>
                    </p>
                    <input
                      type="text"
                      name="client_email"
                      pattern="^(?!.*\.\.)[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$"
                      title="Please enter a valid email format, no double dots, and no trailing dot."
                      required
                      readOnly
                      value={formData?.client_email}
                      onChange={handleFormDataInputChange}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="checkout__order">
                    <h4>Your Order</h4>
                    <div className="checkout__order__products">
                      Products <span>Total</span>
                    </div>
                    <ul>
                      {cartProducts?.map((product, index) => (
                        <li key={index}>
                          {product?.product_name?.length > 20
                            ? product?.product_name.slice(0, 20) + "..."
                            : product?.product_name}
                          x {product?.quantity} <span>{product?.total}</span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="checkout__order__total"
                      style={{
                        borderTop: "1px solid #e1e1e1",
                        paddingTop: "5px",
                      }}
                    >
                      Total <span>{total}</span>
                    </div>
                    <button
                      type="submit"
                      className="site-btn"
                      disabled={buttonDisabled}
                    >
                      {loading ? "Processing..." : "PLACE ORDER"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
