import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlist: [],
  wishlistLength: 0,
};
const Wishlist = createSlice({
  name: "Wishlist",
  initialState,
  reducers: {
    initialWishlistState: (state, action) => {
      const { wishlist } = action.payload;
      return {
        ...state,
        wishlist,
        wishlistLength: wishlist?.length,
      };
    },
    removeWishlist: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { initialWishlistState, removeWishlist } = Wishlist.actions;
export default Wishlist.reducer;
