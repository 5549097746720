import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../img/breadcrumb.jpg";
import NoImage from "../../img/product_no_image.png";
import useAxios from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItem,
  initialCartState,
  productQuantityChange,
} from "../../reduxStore/Cart";
import LoginModal from "../auth/login/LoginModal";
import Loader from "../../components/Loader";
import nodatafound from "../../img/nodatafound.jpg";
import { capitalizeWords, encryptId } from "../../utils/helpers";

const ShoppingCart = () => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartProducts, subTotal, total } = useSelector((state) => state.Cart);
  const { isLoggedIn } = useSelector((state) => state.Auth);
  const [backgroundImages, setBackgroundImages] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const secretKey = "12345";

  const handleProceedToCheckout = () => {
    setShowConfirmationModal(true);
  };

  const confirmCheckout = () => {
    setShowConfirmationModal(false);
    if (!isLoggedIn) {
      // navigate("/auth/login?redirect=shopping-cart");
      setShowLoginModal(true);
    } else {
      navigate("/checkout");
    }
  };

  const cancelCheckout = () => {
    setShowConfirmationModal(false);
    navigate("/");
  };

  const canclemodel = () => {
    setShowConfirmationModal(false);
  };

  const getCartItems = async () => {
    try {
      const response = await axios.get("/cart/get_cart_items");
      const result = response?.data;
      dispatch(initialCartState({ cartProducts: result?.data || [] }));
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const postDeleteCartItem = async (product_variant_id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `/cart/delete_cart_item/${product_variant_id}`
      );
      const result = response?.data;
      getCartItems();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCartItem = (product_variant_id) => {
    if (isLoggedIn) {
      postDeleteCartItem(product_variant_id);
    } else {
      dispatch(deleteCartItem({ product_variant_id }));
    }
  };

  // const postCartItemQuantity = async (product_variant_id, quantity) => {
  //   try {
  //     const response = await axios.post(
  //       `/cart/update_cart_item_quantity/${product_variant_id}`,
  //       { quantity }
  //     );
  //     const result = response?.data;
  //     getCartItems();
  //   } catch (error) {}
  // };

  const handleQuantityChange = async (product_variant_id, quantity) => {
    // Ensure the quantity is a valid number and not less than 1
    quantity = Math.max(0, parseInt(Number(quantity)));
    // If the user is not logged in, handle with Redux state
    if (!isLoggedIn) {
      // Access the cartProducts from Redux (use getState if needed)
      // const cartProducts = getState().cart.cartItems;

      setLoading(true);

      const product = cartProducts.find(
        (item) => item.product_variant_id === product_variant_id
      );

      if (product) {
        // const closingStock = parseInt(product.closing_stock);

        // if (quantity > closingStock) {
        //   alert(`Quantity cannot be greater than stock (${closingStock})`);
        //   setLoading(false);
        //   return;
        // }
        quantity = Math.max(0, parseInt(Number(quantity)));

        // If the quantity is valid, update the cart item quantity in Redux
        dispatch(productQuantityChange({ product_variant_id, quantity }));
        setLoading(false);
      }
      return;
    }

    // If logged in, continue with the backend update (as before)
    setLoading(true);
    try {
      // Find the product in the cartItems array
      const product = cartProducts.find(
        (item) => item.product_variant_id === product_variant_id
      );

      if (product) {
        // const closingStock = parseInt(product.closing_stock);

        // Check if the quantity exceeds closing_stock
        // if (quantity > closingStock) {
        //   alert(`Quantity cannot be greater than stock (${closingStock})`);
        //   return;
        // }

        // If quantity is valid, update the cart item quantity in the backend
        const updateResponse = await axios.post(
          `/cart/update_cart_item_quantity/${product_variant_id}`,
          { quantity }
        );
        const result = updateResponse?.data;
        getCartItems();

        if (result?.success) {
          // After updating, fetch the updated cart items
          getCartItems();
        }
      }
    } catch (error) {
      console.error("Error updating cart item quantity:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleQuantityChange = async (product_variant_id, quantity) => {
  //   if (!isLoggedIn) {
  //     dispatch(productQuantityChange({ product_variant_id, quantity }));
  //     return;
  //   }

  //   quantity = Math.max(1, parseInt(quantity));

  //   try {
  //     // Get the cart items with the updated closing_stock
  //     const response = await axios.get("/cart/get_cart_items");
  //     const cartItems = response?.data?.data;

  //     // Find the product in the cartItems array
  //     const product = cartItems.find(
  //       (item) => item.product_variant_id === product_variant_id
  //     );

  //     if (product) {
  //       const closingStock = parseInt(product.closing_stock);

  //       // Check if the quantity exceeds closing_stock
  //       if (quantity > closingStock) {
  //         alert(`Quantity cannot be greater than stock (${closingStock})`);
  //         return;
  //       }

  //       // If quantity is valid, update the cart item quantity
  //       const response = await axios.post(
  //         `/cart/update_cart_item_quantity/${product_variant_id}`,
  //         { quantity }
  //       );
  //       const result = response?.data;
  //       getCartItems();
  //     }
  //   } catch (error) {
  //     console.error("Error updating cart item quantity:", error);
  //   }
  // };

  return (
    <>
      {loading && <Loader />}
      <section
        className="breadcrumb-section set-bg"
        style={{ backgroundImage: `url(${Breadcrumb})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h2>Shopping Cart</h2>
                <div className="breadcrumb__option">
                  <Link to="/">Home</Link>
                  <span>Shopping Cart</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="shoping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shoping__cart__table">
                {cartProducts?.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="shoping__product">Products</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartProducts?.map((product, index) => (
                        <tr key={index}>
                          <td
                            className="shoping__cart__item hover-cursor-pointer"
                            onClick={() => {
                              const encryptedId = encryptId(
                                product.product_variant_id,
                                secretKey
                              );
                              navigate(
                                `/shop-details/${product?.product_name?.replace(
                                  /[ /]+/g,
                                  "-"
                                )}/${encodeURIComponent(encryptedId)}`
                              );
                            }}
                          >
                            <img
                              src={product.base_image_url || NoImage}
                              alt=""
                            />
                            <h5>
                              {capitalizeWords(product?.product_name || "")}
                            </h5>
                          </td>
                          <td className="shoping__cart__price">
                            ₹&nbsp;{product?.price}
                          </td>
                          {/* <td className="shoping__cart__quantity">
                          <div className="quantity">
                            <div className="pro-qty">
                              <select
                                value={product?.quantity}
                                onChange={(e) => {
                                  handleQuantityChange(
                                    product?.product_variant_id,
                                    e.target.value
                                  );
                                }}
                                style={{
                                  width: "40px",
                                  height: "100%",
                                  textAlign: "center",
                                  background: "#f2f2f2",
                                  border: "none",
                                }}
                              >
                                {[...Array(10)].map((_, i) => (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                ))}
                                  <option value="10+">10+</option>
                              </select>
                              
                            </div>
                          </div>
                        </td> */}

                          <td className="shoping__cart__quantity">
                            <div className="quantity">
                              <div
                                className="pro-qty"
                                style={{ width: "50px" }}
                              >
                                <input
                                  type="text"
                                  value={product?.quantity}
                                  pattern="[0-9]*"
                                  onChange={(e) => {
                                    // Remove non-numeric characters
                                    const newValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    handleQuantityChange(
                                      product?.product_variant_id,
                                      newValue
                                    );
                                  }}
                                  onBlur={(e) => {
                                    // Check if the quantity is less than 1 and reset it to 1
                                    if (
                                      parseInt(e.target.value) < 1 ||
                                      !e.target.value
                                    ) {
                                      handleQuantityChange(
                                        product?.product_variant_id,
                                        1
                                      );
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    // Allow only numbers and control keys (like backspace, delete)
                                    if (
                                      !/^[0-9]$/.test(e.key) &&
                                      ![
                                        "Backspace",
                                        "Delete",
                                        "ArrowLeft",
                                        "ArrowRight",
                                        "Tab",
                                      ].includes(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  style={{
                                    width: "50px",
                                    height: "35px",
                                    textAlign: "center",
                                    background: "#f2f2f2",
                                    border: "none",
                                  }}
                                  min="1"
                                />
                              </div>
                            </div>
                          </td>

                          <td className="shoping__cart__total">
                            ₹&nbsp;
                            {product?.total}
                          </td>
                          <td className="shoping__cart__item__close">
                            <span
                              className="fa fa-trash"
                              onClick={() =>
                                handleDeleteCartItem(
                                  product?.product_variant_id
                                )
                              }
                            ></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data">
                    <img
                      src={nodatafound}
                      alt="No data found"
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        margin: "20px auto",
                        display: "block",
                      }}
                    />
                    {/* <p style={{ textAlign: "center" }}>
                      No products in the cart
                    </p> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="shoping__cart__btns">
                <Link to={"/"} className="primary-btn cart-btn">
                  CONTINUE SHOPPING
                </Link>
                {/* <a href="#" className="primary-btn cart-btn cart-btn-right">
                  <span className="icon_loading"></span>
                  Upadate Cart
                </a> */}
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="shoping__continue">
                <div className="shoping__discount">
                  <h5>Discount Codes</h5>
                  <form action="#">
                    <input type="text" placeholder="Enter your coupon code" />
                    <button type="submit" className="site-btn">
                      APPLY COUPON
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="shoping__checkout">
                <h5>Cart Total</h5>
                <ul>
                  {/* <li>
                    Subtotal{" "}
                    <span>
                      ₹&nbsp;
                      {subTotal}
                    </span>
                  </li> */}
                  <li>
                    Total{" "}
                    <span>
                      ₹&nbsp;
                      {total}
                    </span>
                  </li>
                </ul>
                <span
                  className="primary-btn"
                  onClick={
                    total >= 0 && cartProducts?.length !== 0
                      ? handleProceedToCheckout
                      : undefined
                  }
                  style={{
                    background:
                      cartProducts?.length === 0 || total < 0
                        ? "#8c8c8c"
                        : "#7fad39",
                    pointerEvents:
                      cartProducts?.length === 0 || total < 0 ? "none" : "auto",
                    opacity: cartProducts?.length === 0 || total < 0 ? 0.6 : 1,
                  }}
                >
                  PROCEED TO CHECKOUT
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      {showConfirmationModal && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>Proceed to Checkout?</h4>
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  borderRadius: "500px",
                  border: "none",
                  background: "transparent",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={canclemodel}
              >
                ×
              </button>
            </div>
            <p>Are you sure you want to proceed to checkout?</p>
            <div className="modal-buttons">
              <button className="primary-btn" onClick={cancelCheckout}>
                Continue Shopping
              </button>
              <button className="primary-btn" onClick={confirmCheckout}>
                Yes, Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShoppingCart;
