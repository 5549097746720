import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";

// Example ProtectedRoute component
const ProtectedRoute = ({ isLoggedIn, children, loading }) => {
  if (isLoggedIn === undefined || isLoggedIn === null || loading) {
    // Render a loading spinner or component when loading is true
    return (
      <div style={{ padding: "20vh 0" }}>
        <Loader />
      </div>
    );
  }
  return isLoggedIn ? children : <Navigate to="/auth/login" replace />;
};

export default ProtectedRoute;
